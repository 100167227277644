import React from "react";
import { Formik, Form, Field } from "formik";
import { getDatabase, update, ref } from "firebase/database";
import app from "../firebase";
import ChangeStudentPassword from "./changeStudentPassword.js"
import ChangeStudentEmail from "./changeStudentEmail.js"
import DeleteStudentAccount from "./deleteStudentAccount.js"

class EditStudentDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            message: "",
            view: null,
        };
    }

    toggleModal = () => {
        this.setState((prevState) => ({ 
            showModal: !prevState.showModal, 
            message: "",
            view: null 
        }));
    };

    updateUser = (values) => {
        const database = getDatabase(app);
        const studentRef = ref(database, `schools/${this.props.schoolData.path}/students/${this.props.user.uid}/details`);

        // Build an object of only the values that are provided
        const updatedValues = {};
        Object.keys(values).forEach((key) => {
            if (values[key] !== undefined && values[key] !== null && values[key] !== "") {
                updatedValues[key] = values[key];
            }
        });

        update(studentRef, updatedValues)
            .then(() => {
                this.setState({ message: "Student details updated successfully." });
            })
            .catch((error) => {
                console.error("Error updating student:", error);
                this.setState({ message: "Failed to update student details." });
            });
    };

    renderModalContent() {
        const { user, schoolData } = this.props;
        const { view, message } = this.state;

        // Menu buttons configuration
        const menuButtons = [
            { 
                label: "Edit profile information", 
                view: 'editProfile', 
                variant: '-outline-primary' 
            },
            { 
                label: "Change student password", 
                view: 'changePassword', 
                variant: '-outline-secondary' 
            },
            { 
                label: "Change student email", 
                view: 'changeEmail', 
                variant: '-outline-secondary' 
            },
            { 
                label: "Delete student account", 
                view: 'deleteAccount', 
                variant: '-outline-danger' 
            }
        ];

        return (
            <div style={{textAlign:"left"}} className="modal-body">
                <div className="row g-2 mb-3">
                    {menuButtons.map((button) => (
                        <div key={button.view} className="col-12 col-md-6">
                            <button
                                className={`btn btn${button.variant} w-100`}
                                onClick={() => this.setState({ view: button.view })}
                            >
                                {button.label}
                            </button>
                        </div>
                    ))}
                </div>

                {view === "changePassword" && (
                    <ChangeStudentPassword
                        uid={user.uid}
                        student={user}
                        closeAction={this.toggleModal}
                        schoolData={schoolData}
                    />
                )}

                {view === "changeEmail" && (
                    <ChangeStudentEmail
                        uid={user.uid}
                        email={user.email}
                        student={user}
                        closeAction={this.toggleModal}
                        schoolData={schoolData}
                    />
                )}

                {view === "deleteAccount" && (
                    <DeleteStudentAccount
                        uid={user.uid}
                        email={user.email}
                        student={user}
                        closeAction={this.toggleModal}
                        schoolData={schoolData}
                    />
                )}

                {view === "editProfile" && (
                    <Formik
                        initialValues={{
                            firstName: user.firstName || "",
                            lastName: user.lastName || "",
                            yearGroup: user.yearGroup || "",
                            class: user.class || "",
                            gender: user.gender || "",
                            sen: user.sen || false,
                            pupilPremium: user.pupilPremium || false,
                            moreAble: user.moreAble || false,
                            lowAttaining: user.lowAttaining || false,
                        }}
                        onSubmit={(values) => {
                            this.updateUser(values);
                            this.toggleModal();
                        }}
                    >
                        {() => (
                            <Form>
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <label className="form-label">First Name</label>
                                        <Field name="firstName" type="text" className="form-control" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Last Name</label>
                                        <Field name="lastName" type="text" className="form-control" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Year Group</label>
                                        <Field name="yearGroup" type="number" className="form-control" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Class/Tutor Group</label>
                                        <Field name="class" type="text" className="form-control" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Gender</label>
                                        <Field as="select" name="gender" className="form-select">
                                            <option value="">Select Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </Field>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end mt-3">
                                    <button 
                                        type="button" 
                                        className="btn btn-secondary me-2" 
                                        onClick={this.toggleModal}
                                    >
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        Save Changes
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}

                {message && (
                    <div className="alert alert-info mt-3" role="alert">
                        {message}
                    </div>
                )}
            </div>
        );
    }

    render() {
        const { user } = this.props;
        const { showModal } = this.state;

        return (
            <div>
                <button 
                    className="btn btn-outline-secondary" 
                    onClick={this.toggleModal}
                >
                    Edit Student Details
                </button>

                {showModal && (
                    <div className="modal show d-block" tabIndex="-1">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Edit {user.firstName}'s Details</h5>
                                    <button 
                                        type="button" 
                                        className="btn-close" 
                                        onClick={this.toggleModal}
                                    ></button>
                                </div>
                                {this.renderModalContent()}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default EditStudentDetails;