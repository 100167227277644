import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { getFunctions, httpsCallable } from "firebase/functions";
import app from "../firebase";

class ChangeStudentEmail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            submitAttempt: false,
            message: "",
        };
    }

    handleSubmit = async (values) => {
        const { email } = values;
        const { uid } = this.props;

        if (!uid) {
            this.setState({ message: "No student selected." });
            return;
        }

        const functions = getFunctions(app);
        const changeStudentEmail = httpsCallable(functions, "changeStudentEmail");

        try {
            const response = await changeStudentEmail({
                studentUid: uid,
                newEmail: email,
                schoolId: this.props.schoolData.path,
            });

            if (response.data.error) {
                this.setState({ message: response.data.error });
            } else {
                this.setState({ message: "Email changed successfully." });
                setTimeout(() => {
                    this.props.closeAction();
                }, 2000);
            }
        } catch (error) {
            console.error("Error changing email:", error);
            this.setState({ message: "Failed to change email." });
        }
    };

    validate = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = "Email is required";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
            errors.email = "Invalid email address";
        }
        return errors;
    };

    render() {
        return (
            <div>
                        <Formik
                            initialValues={{ email: this.props.email || "" }}
                            validate={this.validate}
                            onSubmit={this.handleSubmit}
                        >
                            <Form>
                                <div className="form-group">
                                <h2>Change Student Email</h2>

                                    <h4>{this.props.student?.firstName} {this.props.student?.lastName}</h4>
                                    <p>{this.props.student?.email} </p>
                                    <label htmlFor="email">New email for student</label>
                                    <Field type="email" id="email" name="email" className="form-control" />
                                    <ErrorMessage name="email" component="div" className="text-danger" />

                                    <button type="submit" className="btn btn-primary">
                                        Submit
                                    </button>
                                    {this.state.message && <div className="mt-3">{this.state.message}</div>}
                                </div>
                            </Form>
                        </Formik>
                    </div>
           
        );
    }
}

export default ChangeStudentEmail;
