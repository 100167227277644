const areasConfig = [
    {
        name: "Learners", stage: "secondary", imageUrl: "https://i0.wp.com/www.worldclass-schools.org/wp-content/uploads/2020/11/WCS05-Learners.jpg.webp?resize=230%2C230&ssl=1", path: "learners", motto: "I am an excellent learner, dedicated to life-long learning because I", color: "#2a2d7c",
        characteristics: [
            {
                id: 324532,
                index: 0,
                step: 0,
                icon: "lightbulb",
                name: "understand the importance of learning from mistakes, and build up intellectual and emotional resilience",
                improvements: ["I will ask a friend or a parent for some constructive criticism on a piece of work. I will take that feedback onboard and make changes if needed.",
                    "I will start a project involving a subject that I feel least confident in for a week, and produce something that I am proud of",
                    "I will keep a journal of my mistakes and reflect on what I've learned from them"
                ],
                understandImage: "https://firebasestorage.googleapis.com/v0/b/beworldclass-801f5.appspot.com/o/staticImages%2FStairs.png?alt=media&token=1bc5b671-9005-4f8d-a9d7-ae8131a3553a",
                understand: "<h4>Learning from Mistakes and Building up Intellectual and Emotional Resilience</h4> <p><strong>A rephrased version:</strong> Understanding that it's okay to make mistakes and learning from them will help you become stronger and smarter.</p> <p><strong>Description:</strong> Learning from your mistakes means taking the time to reflect on what went wrong, what you could have done differently, and what you can do better next time. Building up intellectual and emotional resilience means developing the ability to cope with setbacks, challenges, and stress in a positive way. It also involves learning how to bounce back from difficult situations and keep moving forward.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Reflect on your mistakes and identify what you can learn from them. For example, if you get a question wrong on a test, think about why you got it wrong and how you can improve your understanding of the topic.</li> <li>Take on challenges that push you outside of your comfort zone. For example, join a club or team, try a new hobby, or take a challenging class.</li> <li>Develop positive self-talk and a growth mindset. For example, instead of thinking \"I'm not good at this,\" try thinking \"I can learn and improve with practice.\"</li> <li>Seek support from others when you need it. For example, talk to a trusted friend, family member, or teacher when you're feeling stressed or overwhelmed.</li> </ul>"
            },
            {
                id: 12346576352,
                index: 0.5,
                step: 0,
                icon: "brain",
                name: "exercise intellectual curiosity, and work independently, creatively and inventively",
                improvements: ["I will take a class or workshop on a topic that interests me but is not related to my current studies.",
                    "I will work on a personal project that is not related to school work"
                ],

                understandImage: "https://firebasestorage.googleapis.com/v0/b/beworldclass-801f5.appspot.com/o/staticImages%2FTelescope.png?alt=media&token=935fc996-9b56-47b2-828c-01857959712e",
                understand: "<h4>Exercising Intellectual Curiosity and Working Independently, Creatively and Inventively</h4> <p><strong>A rephrased version:</strong> Being curious and thinking creatively on your own will help you come up with unique and innovative ideas.</p> <p><strong>Description:</strong> Exercising intellectual curiosity means having a strong desire to learn and understand new things. It involves asking questions, seeking out information, and exploring ideas. Working independently, creatively, and inventively means being able to think outside the box, come up with new solutions to problems, and work on projects without needing constant guidance or direction.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Ask questions and seek out information on topics that interest you. For example, if you're curious about space, read books, watch documentaries, or listen to podcasts about astronomy and space exploration.</li> <li>Take on projects or assignments that allow you to be creative and come up with your own ideas. For example, if you're given a writing assignment, try coming up with a unique angle or perspective that hasn't been explored before.</li> <li>Experiment with new ideas and take risks. For example, if you're working on a science project, try an untested hypothesis or method.</li> <li>Work on projects independently, without needing constant guidance or direction. For example, if you're given a research assignment, break it down into smaller tasks and set deadlines for each task to make sure you complete the project on time.</li> </ul> </p>"
            },
            {
                id: 12452,
                index: 1,
                step: 1,
                icon: "rocket",
                name: "demonstrate an entrepreneurial spirit",
                improvements: ["I will research and come up with a business idea and create a business plan.",
                    "I will find a problem at home or at school, and find an imaginative solution.",
                    "I will find a way to raise money for charity",
                    "I will find a way to help others as research for a product or service"
                ],
                understandImage: "https://firebasestorage.googleapis.com/v0/b/beworldclass-801f5.appspot.com/o/staticImages%2Ftrfv_A_rocket_ship_blasting_off_into_space._block_color_blue_ye_540898f2-8352-470d-a402-ed8ec7be8eec2.png?alt=media&token=43ed1dea-2f4d-4fd4-9480-e3878030e356",
                understand: "<h4>Demonstrating an Entrepreneurial Spirit</h4> <p><strong>A rephrased version:</strong> Having the drive and creativity to turn an idea into a successful project or business.</p> <p><strong>Description:</strong> Demonstrating an entrepreneurial spirit means having the skills and mindset necessary to identify and pursue opportunities, take calculated risks, and create value. It involves being proactive, innovative, and persistent in achieving goals.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Identify problems or challenges in your community or school and come up with creative solutions to address them. For example, if you notice a lack of recycling bins, start a recycling initiative in your school.</li> <li>Create a business plan for a product or service that you think would be successful. For example, if you're interested in fashion, come up with a plan for a sustainable clothing line.</li> <li>Take calculated risks by trying new things and pushing yourself outside of your comfort zone. For example, if you're interested in public speaking, volunteer to give a speech at an event.</li> <li>Be persistent in pursuing your goals, even when faced with setbacks or obstacles. For example, if you encounter difficulties with your project, seek out advice and resources to help you overcome the challenges.</li> </ul> </p>"
            },
            {
                id: 12436576362,
                index: 2,
                step: 1,
                icon: "trophy",
                name: "reflect on my achievements, revise and improve my ideas",
                improvements: [
                    "I will gather feedback from others and take it into consideration when revising my ideas.",
                    "I will research new information and explore different perspectives on my ideas.",
                    "I will experiment with different approaches and test my ideas in a low-risk environment."
                ],
                understandImage: "https://firebasestorage.googleapis.com/v0/b/beworldclass-801f5.appspot.com/o/staticImages%2Freflect.png?alt=media&token=effd1c1c-651c-4389-882e-ee1e81142ebf",
                understand: "<h4>Reflecting on Achievements, Revising and Improving Ideas</h4> <p><strong>A rephrased version:</strong> Looking back on your accomplishments and making improvements to your ideas can help you achieve even greater success in the future.</p> <p><strong>Description:</strong> Reflecting on achievements means taking the time to assess your successes and accomplishments, both big and small. It involves analyzing what went well, what could have gone better, and how you can improve in the future. Revising and improving ideas means taking feedback, evaluating and refining your original ideas to make them better and more effective.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Set goals and regularly evaluate your progress towards achieving them. For example, if your goal is to improve your grades, evaluate your progress at the end of each semester and create a plan to address any areas that need improvement.</li> <li>Reflect on past successes and challenges to identify patterns and areas for improvement. For example, if you received a good grade on a project, think about what you did well and how you can replicate that success in future projects.</li> <li>Solicit feedback from peers, teachers or mentors to get different perspectives on your work. Use that feedback to make changes and improvements to your ideas. For example, if you're working on a group project, ask your group members for feedback on your contributions and incorporate their suggestions into your work.</li> <li>Revise and refine your ideas based on your reflections and feedback. For example, if you're writing an essay, read through it and make changes to improve the clarity of your argument and organization of your thoughts.</li> </ul> </p>"
            },
            {
                id: 75643,
                index: 3,
                step: 2,
                icon: "cogs",
                name: "use strategies for effective learning, such as the ability to memorize, summarize, rephrase and review",
                improvements: ["I will create flashcards to help me memorize key information.",
                    "I will summarize important information and main ideas after reading or listening to something.",
                    "I will practice rephrasing information in my own words to help me better understand it.",
                    "I will review material regularly to keep it fresh in my mind."
                ],
                understand: "<h4>Using Strategies for Effective Learning</h4> <p><strong>A rephrased version:</strong> To learn well, it's important to use different methods to help you remember, understand, and use information effectively.</p> <p><strong>Description:</strong> Using strategies for effective learning means using a variety of techniques to help you learn and retain information. These techniques can include memorizing, summarizing, rephrasing, and reviewing information. By using these strategies, you can improve your understanding of complex topics and increase your ability to apply what you have learned to real-world situations.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Memorize important facts and concepts by using mnemonic devices, such as acronyms, rhymes, or visual imagery.</li> <li>Summarize complex topics by breaking them down into smaller, more manageable pieces of information. This can include creating an outline, using bullet points, or writing a summary of the key points.</li> <li>Rephrase difficult concepts in your own words to help you better understand them. This can include explaining the concept to someone else or writing it down in your own words.</li> <li>Review information regularly to reinforce your learning and help you remember it over time. This can include reviewing notes, re-reading textbook chapters, or practicing with flashcards.</li> </ul> </p>"
            },
            {
                id: 4123445412,
                index: 4,
                step: 2,
                icon: "book-reader",
                name: "practice reading for enjoyment, accompanied by high levels of critical thinking",
                improvements: ["I will read a variety of genres and types of books, both fiction and non-fiction.",
                    "I will actively engage with the text by asking questions and making connections to my own life and experiences.",
                    "I will practice critical thinking by analyzing the themes, characters and plot of the texts I read.",
                    "I will keep a reading journal where I can reflect on what I've read and what I've learned from it."
                ],
                understandImage: "https://firebasestorage.googleapis.com/v0/b/beworldclass-801f5.appspot.com/o/staticImages%2Fbook.png?alt=media&token=060aad7b-ccfa-46a9-ac09-4730551105c0",
                understand: "<h4>Practicing Reading for Enjoyment and Critical Thinking</h4> <p><strong>A rephrased version:</strong> Reading can be fun and help you think more deeply about the world around you.</p> <p><strong>Description:</strong> Practicing reading for enjoyment and critical thinking means reading for pleasure while also engaging in higher-level thinking skills. This includes analyzing text, questioning what you're reading, making connections to your own life and experiences, and considering different perspectives. By practicing this skill, you can broaden your understanding of the world, improve your vocabulary and comprehension skills, and develop your ability to think critically about what you read.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Choose books that interest you and that you enjoy reading. This can include novels, magazines, or online articles.</li> <li>As you read, stop and ask yourself questions about what you've read, such as what you think the author's purpose is, what you're learning, or what connections you can make to your own life.</li> <li>Consider different perspectives on what you're reading, and think about how your own perspective might be influenced by the text.</li> <li>Use critical thinking skills to evaluate what you're reading, such as analyzing the author's argument or determining the reliability of the source.</li> <li>Write a summary or a review of what you've read to help you process and remember the information.</li> </ul> </p>"
            },
            {
                id: 9273641237,
                index: 5,
                step: 3,
                icon: "search",
                name: "am an excellent researcher",
                improvements: [
                    "I will identify and utilize credible sources of information, such as libraries, academic journals, and reputable online resources.",
                    "I will carefully analyze and interpret data, drawing meaningful conclusions and recognizing patterns or trends.",
                    "I will keep a research journal to track my progress, questions, and insights as I delve into various topics.",
                    "I will communicate my research findings clearly, using visual aids like charts or graphs when necessary.",
                    "I will constantly seek to improve my research skills by seeking feedback from teachers, mentors, or peers."
                ],
                understand: "<h4>Being an Excellent Researcher</h4> <p><strong>A rephrased version:</strong> Research is like being a detective, looking for answers and clues in different places, and putting them together to understand something new.</p> <p><strong>Description:</strong> Being an excellent researcher means having the ability to find, analyze, and understand information from various sources. It requires critical thinking, attention to detail, and a willingness to explore different perspectives and methodologies. Research skills are valuable in many subjects, from history and science to literature and geography.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Start with a clear research question or goal in mind, and then gather information from books, websites, experts, or other sources.</li> <li>Analyze what you find, looking for accuracy, relevance, and different viewpoints.</li> <li>Take notes on what you learn, keeping track of where you found the information.</li> <li>Organize your findings into a clear, coherent report or presentation.</li> <li>Reflect on what you have learned and how you can use this knowledge in the future.</li> </ul> </p>"
            },
            {
                id: 5123445653,
                index: 6,
                step: 3,
                icon: "chalkboard-teacher",
                name: "am an exceptional learning role model to members of the school community, at all ages, and levels.",
                improvements: [
                    "I will engage with students and teachers across different age groups to share my passion for learning.",
                    "I will volunteer to lead study groups or workshops that promote a positive learning environment.",
                    "I will consistently demonstrate curiosity and eagerness to learn by participating in various school activities and events.",
                    "I will seek opportunities to mentor or tutor others, promoting a supportive and collaborative learning culture."
                ],
                understand: "<h4>Being an Exceptional Learning Role Model</h4> <p><strong>A rephrased version:</strong> You can inspire others in your school by being a great example of how to learn and grow.</p> <p><strong>Description:</strong> Being an exceptional learning role model means showing others in your school community how to have a positive attitude towards learning, and how to strive for growth and understanding in all subjects. It involves sharing your enthusiasm for learning, and helping others to develop their skills and confidence.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Help others with their studies by offering support, guidance, or tutoring.</li> <li>Participate in or lead clubs, workshops, or study groups that focus on learning and growing together.</li> <li>Show enthusiasm and curiosity in your own studies and encourage others to do the same.</li> <li>Work with teachers and other students to create a positive and inclusive learning environment.</li> <li>Share your own learning journey, including successes and challenges, to motivate and inspire others.</li> </ul> </p>"
            }
        ]
    },
    {
        name: "Leaders", stage: "secondary", imageUrl: "https://i0.wp.com/www.worldclass-schools.org/wp-content/uploads/2020/11/WCS05-Leaders-480x480.jpg?resize=230%2C230&ssl=1", path: "leaders", motto: "I am a good Leader because I", color: "#753996",
        characteristics: [
            {
                id: 4123445413,
                index: 0,
                step: 0,
                icon: "hands-helping",
                name: "inspire, encourage and recognise others",
                improvements: [
                    "I will offer positive feedback and support to my friends, family, or teammates when they achieve something or need encouragement.",
                    "I will recognize the efforts and achievements of others by expressing my gratitude and acknowledging their hard work.",
                    "I will seek to inspire others by setting a positive example and sharing my own passions and goals.",
                    "I will create a 'wall of recognition' at home or in my classroom to highlight the achievements and positive qualities of those around me."
                ],
                understand: "<h4>Inspire, Encourage and Recognise Others</h4> <p><strong>A rephrased version:</strong> Help others feel motivated, supported, and valued.</p> <p><strong>Description:</strong> Inspiring, encouraging, and recognizing others means lifting people up through positive words, actions, and acknowledgment. It involves offering support and motivation, celebrating successes, and showing appreciation for the efforts and qualities of those around you. This can lead to a positive environment where everyone feels valued and empowered.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Tell a friend or family member that you're proud of them and explain why.</li> <li>Write a thank-you note to someone who has helped or inspired you.</li> <li>Share an inspiring story or quote with others to motivate them.</li> <li>Celebrate the achievements of your classmates or coworkers by creating a recognition board.</li> <li>Offer to help someone who is struggling, and provide encouragement along the way.</li> </ul> </p>"
            },
            {
                id: 5678912345,
                index: 1,
                step: 0,
                icon: "calendar-check",
                name: "know how to plan effectively",
                improvements: [
                    "I will create a weekly schedule to manage my time for school, hobbies, and other activities.",
                    "I will set specific goals and deadlines for my tasks, and track my progress regularly.",
                    "I will break down big projects into smaller, manageable tasks and prioritize them accordingly.",
                    "I will use tools like planners, calendars, or apps to organize my schedule and set reminders."
                ],
                understand: "<h4>Knowing How to Plan Effectively</h4> <p><strong>A rephrased version:</strong> Learning how to organize your time and tasks helps you reach your goals without feeling overwhelmed.</p> <p><strong>Description:</strong> Knowing how to plan effectively means being able to organize your time and tasks so that you can accomplish what you want without feeling stressed or overwhelmed. This involves setting clear goals, breaking down large projects into smaller parts, prioritizing your tasks, and using tools like calendars and reminders to keep track of your schedule.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Make a daily or weekly plan that includes your schoolwork, chores, hobbies, and other activities.</li> <li>Set clear and specific goals for each task, and give yourself deadlines to help you stay on track.</li> <li>Use a planner, calendar, or app to help you organize your tasks and remind you of what you need to do.</li> <li>Learn to prioritize your tasks so that you can focus on what's most important first.</li> <li>Take pictures of your planner or schedule to track your progress and show how you're learning to plan effectively.</li> </ul> </p>"
            },
            {
                id: 5233445523,
                index: 2,
                step: 1,
                icon: "clock",
                name: "am efficient",
                improvements: [
                    "I will create a daily schedule to manage my time effectively, ensuring I complete tasks within set deadlines.",
                    "I will prioritize my tasks by importance, focusing on what needs to be done first.",
                    "I will minimize distractions when working on tasks, by turning off unnecessary notifications or finding a quiet place to work.",
                    "I will reflect on my daily activities, identifying areas where I can improve my efficiency and making necessary adjustments."
                ],
                understand: "<h4>Being Efficient</h4> <p><strong>A rephrased version:</strong> Being efficient means getting tasks done quickly and without wasting time or effort.</p> <p><strong>Description:</strong> Efficiency involves organizing and managing your time and resources so you can achieve your goals without unnecessary waste. It includes setting clear goals, planning ahead, prioritizing tasks, minimizing distractions, and reflecting on your progress to make improvements. Being efficient helps you complete more in less time, leaving more time for relaxation and enjoyment.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Make a to-do list and prioritize tasks, focusing on the most important ones first.</li> <li>Set specific deadlines for each task and stick to them.</li> <li>Find ways to minimize distractions, such as turning off notifications or finding a quiet place to work.</li> <li>Reflect on your day and identify areas where you can improve your efficiency, such as by avoiding procrastination or breaking tasks into smaller, manageable parts.</li> <li>Take pictures of your daily or weekly schedule, and evidence of completed tasks to show your efficiency in action.</li> </ul> </p>"
            },
            {
                id: 5241516835,
                index: 3,
                step: 1,
                icon: "balance-scale",
                name: "am fair, trustworthy and ethical",
                improvements: [
                    "I will treat others with respect and make decisions that are fair and impartial, avoiding favoritism.",
                    "I will keep my promises and follow through on commitments, showing others that I am dependable.",
                    "I will be honest in all my interactions and avoid lying or deceiving others.",
                    "I will reflect on my own values and make sure that my actions align with what I believe is right and ethical."
                ],
                understand: "<h4>Being Fair, Trustworthy and Ethical</h4> <p><strong>A rephrased version:</strong> Being fair, honest, and doing what's right, even when no one is watching.</p> <p><strong>Description:</strong> Being fair, trustworthy, and ethical means treating others with respect, being honest, and acting in a way that is morally right. It involves being fair and unbiased in your interactions, keeping your word, and behaving ethically even in difficult situations. This quality helps you build strong relationships with others and creates a sense of trust and integrity.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Treat friends, family members, and others with respect and fairness, such as by taking turns or sharing.</li> <li>Keep promises or commitments you make, such as completing a task you've agreed to do.</li> <li>Make decisions that are fair and unbiased, like choosing team members for a game without favoring your friends.</li> <li>Act ethically in school and at home, like admitting if you've made a mistake instead of hiding it or lying.</li> <li>Reflect on your actions and consider whether they align with your personal values and what you believe is right.</li> </ul> </p>"
            },
            {
                id: 5472891234,
                index: 4,
                step: 2,
                icon: "check-circle",
                name: "make effective decisions according to reliable evidence",
                improvements: [
                    "I will identify a problem or decision that needs to be made and gather relevant information from trustworthy sources.",
                    "I will evaluate the information, looking for facts and evidence, and avoiding opinions or biases.",
                    "I will weigh the pros and cons of each option, considering how each decision will affect me and others around me.",
                    "I will make a well-informed decision based on the evidence, and reflect on the process in a decision-making journal, where I can track the outcomes."
                ],
                understand: "<h4>Making Effective Decisions Based on Reliable Evidence</h4> <p><strong>A rephrased version:</strong> Making good choices by using solid facts and information you can trust.</p> <p><strong>Description:</strong> Making effective decisions according to reliable evidence means using facts, trustworthy information, and clear thinking to make choices. This includes identifying a problem or decision, finding good information, analyzing the options, and making a well-informed choice. By doing this, you can make decisions that are wise, fair, and likely to lead to good results.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Identify a decision that needs to be made in your daily life, like choosing a meal, a book to read, or a route to school.</li> <li>Gather information from trustworthy sources like books, experts, or credible websites, rather than just asking friends or guessing.</li> <li>Think carefully about the information, and look for facts rather than opinions.</li> <li>Weigh the pros and cons, thinking about how each choice will affect you and others.</li> <li>Make a decision, and keep track of what happens, so you can learn from it.</li> </ul> </p>"
            },
            {
                id: 8123947201,
                index: 5,
                step: 2,
                icon: "bullseye",
                name: "set myself goals and targets that enable me to reach my potential",
                improvements: [
                    "I will identify specific areas in my life or studies where I want to improve and set achievable targets for them.",
                    "I will create a timeline with milestones to track my progress towards my goals.",
                    "I will regularly review my goals and adjust them as needed to ensure they remain challenging and relevant.",
                    "I will keep a journal or visual board to record my achievements, reflect on my progress, and stay motivated."
                ],
                understand: "<h4>Setting Goals and Targets to Reach My Potential</h4> <p><strong>A rephrased version:</strong> Making plans and setting goals helps me become the best I can be.</p> <p><strong>Description:</strong> Setting goals and targets means choosing what you want to achieve, making a plan to reach those achievements, and then working on that plan. This helps you focus on what's important, use your time wisely, and be proud of what you accomplish.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Think about what you want to achieve in school, sports, hobbies, or other areas of your life.</li> <li>Write down your goals and break them into smaller, manageable parts.</li> <li>Set deadlines for each part of your goal, and find ways to remind yourself of them.</li> <li>Review your progress regularly and make changes if you need to.</li> <li>Ask for help or support from family, friends, or teachers if you need it.</li> <li>Take pictures or keep a journal of your progress, and celebrate your successes along the way.</li> </ul> </p>"
            }

        ]
    },
    {
        name: "Commitment to achievement", stage: "secondary", imageUrl: "https://i0.wp.com/www.worldclass-schools.org/wp-content/uploads/2020/11/WCS05-Achievement-480x480.jpg?resize=230%2C230&ssl=1", path: "commitment", motto: "I am I consistently out-perform students from similar contexts, and I am committed to continually improving my performance because I", color: "#9f1f63",
        characteristics: [
            {
                id: 8745962739,
                index: 0,
                step: 0,
                icon: "users",
                name: "know how to develop positive relationships with different groups of people",
                improvements: [
                    "I will engage in conversation and listen actively to understand different perspectives.",
                    "I will show respect and empathy towards others, regardless of their background or beliefs.",
                    "I will work cooperatively in a team, recognizing and valuing the contributions of others.",
                    "I will reflect on my interactions and think of ways to improve my communication and relationship-building skills."
                ],
                understand: "<h4>Developing Positive Relationships with Different Groups of People</h4> <p><strong>A rephrased version:</strong> Learning to get along well with many types of people.</p> <p><strong>Description:</strong> Knowing how to develop positive relationships with different groups of people means being able to communicate, cooperate, and connect with others, regardless of their background, beliefs, or personality. This includes understanding and respecting others' opinions, working together, and building trust. By developing this skill, you can create more harmonious interactions in your community, school, or workplace.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Talk to someone from a different background and try to understand their point of view.</li> <li>Join a club or group that includes people with different interests and backgrounds.</li> <li>Show empathy and support when someone is upset or needs help.</li> <li>Work on a team project and appreciate the different skills and ideas that each person brings.</li> <li>Reflect on a situation where you successfully resolved a disagreement or misunderstanding, and think about what you did well and what you could improve.</li> </ul> </p>"
            },
            {
                id: 5123445413,
                index: 1,
                step: 0,
                icon: "mountain",
                name: "achieve my potential and overcome obstacles",
                improvements: [
                    "I will identify the obstacles that are holding me back and create a plan to overcome them.",
                    "I will set clear and achievable goals and monitor my progress towards reaching them.",
                    "I will seek help from teachers, friends, or family when I face challenges I cannot overcome alone.",
                    "I will reflect on my achievements and challenges, focusing on what I learned and how I can improve."
                ],
                understand: "<h4>Achieving Potential and Overcoming Obstacles</h4> <p><strong>A rephrased version:</strong> Working hard to be the best you can be and finding ways to get past problems or difficulties.</p> <p><strong>Description:</strong> Achieving your potential and overcoming obstacles means pushing yourself to do your best and not letting challenges or difficulties stop you. This involves setting goals, working hard to reach them, and finding ways to get past any problems you might face along the way.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Write down your goals and the steps you'll take to achieve them. Break big goals into smaller, manageable tasks.</li> <li>If you hit a roadblock, don't give up. Think about different ways to overcome the obstacle, or ask for help if you need it.</li> <li>Keep track of your progress and celebrate your achievements, no matter how small. Learn from mistakes and see them as opportunities to grow.</li> <li>Join a club or team that challenges you, or take on a project that pushes you out of your comfort zone.</li> <li>Reflect on your progress regularly and think about what you've learned and what you can do better next time.</li> </ul> </p>"
            },
            {
                id: 9128494021,
                index: 2,
                step: 1,
                icon: "medal",
                name: "have an area of expertise or specialism",
                improvements: [
                    "I will identify a subject or hobby I am passionate about and dedicate time to learning and mastering it.",
                    "I will seek guidance from experts or professionals in my chosen field to deepen my understanding and improve my skills.",
                    "I will actively participate in activities, clubs, or online communities that focus on my area of expertise or specialism.",
                    "I will document my progress by keeping a journal or portfolio, including pictures, of my work, achievements, and reflections."
                ],
                understand: "<h4>Having an Area of Expertise or Specialism</h4> <p><strong>A rephrased version:</strong> Becoming really good at something specific, like a subject or hobby.</p> <p><strong>Description:</strong> Having an area of expertise or specialism means focusing on a specific subject, hobby, or skill and becoming an expert in it. It requires dedication, continuous learning, and practice. This can help you become more confident, find a community of like-minded people, and even lead to career opportunities.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Choose a subject or hobby that you are passionate about and want to excel in.</li> <li>Take classes, attend workshops, or find online resources to help you learn and grow in your chosen field.</li> <li>Join clubs or online communities where you can share your interests and learn from others.</li> <li>Create a portfolio of your work, whether it's artwork, writing, coding, or any other skill, and include pictures and reflections on your progress.</li> <li>Seek out mentors or professionals who can guide you and provide feedback on your work.</li> </ul> </p>"
            },
            {
                id: 9876543210,
                index: 3,
                step: 1,
                icon: "tools",
                name: "can demonstrate methods and tools of continual improvement",
                improvements: [
                    "I will set clear and achievable goals for personal or school projects and track my progress towards them.",
                    "I will use tools such as a planner or journal to regularly reflect on what I'm doing well and what I can improve.",
                    "I will seek feedback from teachers, friends, or family to understand what I can do better and implement those changes.",
                    "I will experiment with different strategies and methods to find what works best for me, continually refining and improving."
                ],
                understand: "<h4>Demonstrating Methods and Tools of Continual Improvement</h4> <p><strong>A rephrased version:</strong> Continually getting better at something by using different ways and tools.</p> <p><strong>Description:</strong> Demonstrating methods and tools of continual improvement means always trying to get better at something, whether it's a hobby, school subject, or personal skill. This includes setting goals, tracking progress, using tools like planners or journals, seeking feedback, and experimenting with different strategies. By doing this, you can grow and get better over time.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Set a goal to improve at something, like getting better grades, learning a new hobby, or improving at a sport.</li> <li>Use a planner or journal to keep track of your progress and reflect on what you're learning.</li> <li>Ask teachers, friends, or family for feedback on how you're doing and what you can improve.</li> <li>Try different ways of doing things to find what works best for you, and keep refining and improving your methods.</li> <li>Take pictures of your progress, like a photo of a project you're working on, or a snapshot of your grades improving.</li> </ul> </p>"
            },
            {
                id: 5839204831,
                index: 4,
                step: 2,
                icon: "sync-alt",
                name: "am agile in response to changing needs and circumstances",
                improvements: [
                    "I will practice adapting to unexpected changes by trying new hobbies or changing my routine.",
                    "I will work on a project that requires me to adapt to new information or unexpected obstacles.",
                    "I will actively listen and respond to feedback from others, making necessary changes to my behavior or ideas.",
                    "I will reflect on my experiences with change, noting what I've learned and how I can improve in the future."
                ],
                understand: "<h4>Being Agile in Response to Changing Needs and Circumstances</h4> <p><strong>A rephrased version:</strong> Being flexible and able to adapt when things change around you.</p> <p><strong>Description:</strong> Being agile means being able to quickly adapt to new situations, changes, or unexpected challenges. It's about being flexible and open to new ideas, and being willing to change your plans or your thinking when needed. This skill is important in many areas of life, including school, work, and personal relationships, as it helps you deal with uncertainty and remain effective in a changing environment.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Try a new hobby or take on a new role in a group or team, adjusting to the new demands and learning as you go.</li> <li>Work on a project and adapt your approach when you encounter unexpected obstacles or new information.</li> <li>Listen to feedback from others and be willing to make changes to your ideas or behavior based on what you hear.</li> <li>Reflect on times when you've had to adapt to change, and think about what you've learned and how you can continue to improve your agility in the future.</li> </ul> </p>"
            },
            {
                id: 5284919225,
                index: 5,
                step: 2,
                icon: "chart-line",
                name: "want to improve my learning continually",
                improvements: [
                    "I will set specific and measurable learning goals for myself and track my progress regularly.",
                    "I will reflect on my learning process, identifying what works well for me and what I need to change or enhance.",
                    "I will seek feedback from teachers, peers, or family members to understand areas for improvement and act on it.",
                    "I will explore new learning strategies, techniques, or tools that can help me better understand the subjects I'm studying.",
                    "I will create a learning journal or portfolio where I can document my progress, successes, challenges, and insights."
                ],
                understand: "<h4>Wanting to Improve My Learning Continually</h4> <p><strong>A rephrased version:</strong> Always trying to get better at learning.</p> <p><strong>Description:</strong> Wanting to improve your learning continually means always looking for ways to get better at understanding and remembering new information. It's about setting goals, trying new methods, getting feedback, and reflecting on what you've learned. By doing this, you can become a more effective and confident learner, ready to take on new challenges.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Set clear learning goals and check how you're doing regularly.</li> <li>Ask for feedback from people who know about the subject or how you learn, like teachers or family members.</li> <li>Try new ways of learning, like using flashcards, watching videos, or joining study groups.</li> <li>Think about what you've learned and what you could do better next time, writing it down in a journal if it helps.</li> <li>Use online tools or apps that help you learn in a fun and engaging way.</li> </ul> </p>"
            }



        ]
    },
    {
        name: "Community",
        stage: "secondary",
        imageUrl: "https://i0.wp.com/www.worldclass-schools.org/wp-content/uploads/2020/11/WCS05-Community-480x480.jpg?resize=230%2C230&ssl=1",
        path: "community",
        motto: "I am an active participant in my school, local or relevant communities because I",
        color: "#c6972c",
        characteristics: [
            {
                id: 5215456123,
                index: 0,
                step: 0,
                icon: "user-check",
                name: "ensure I am accountable for my performance; I identify constraints to my performance and accept ownership of problems and responsibility for solving them.",
                improvements: [
                    "I will set specific goals for my performance and monitor my progress towards achieving them, taking pictures of my progress chart or tracking journal.",
                    "I will identify challenges or constraints that might hinder my performance and brainstorm solutions to overcome them, keeping a record in a problem-solving diary.",
                    "I will accept responsibility for any problems that arise and work actively to fix them, documenting the problem and my solution in a report.",
                    "I will seek feedback from teachers, parents, or peers on my performance and make necessary adjustments, keeping a feedback log to show my changes."
                ],
                understand: "<h4>Being Accountable for Your Performance</h4> <p><strong>A rephrased version:</strong> Taking responsibility for your own work, finding out what might go wrong, and taking care of any problems yourself.</p> <p><strong>Description:</strong> Being accountable for your performance means that you take ownership of your work, understand what might hold you back, and actively work to fix any problems. This includes setting clear goals, understanding what might get in the way, taking responsibility for any issues, and seeking feedback to make improvements.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Set goals for a project or task, and keep track of your progress, taking pictures as evidence of your hard work.</li> <li>Think about what might go wrong and plan ahead to avoid problems or fix them if they happen.</li> <li>Take responsibility for any problems and work out how to fix them, rather than blaming someone else or ignoring them.</li> <li>Ask for feedback from others and use it to make your work even better, keeping a record of the advice and how you used it.</li> </ul> </p>"
            },
            {
                id: 1234567890,
                index: 1,
                step: 0,
                icon: "balance-scale",
                name: "have a balanced approach to satisfying a range of interested parties with conflicting demands",
                improvements: [
                    "I will identify the various interested parties and understand their respective demands or interests.",
                    "I will work to understand the reasons behind each party's demands and prioritize them based on urgency and importance.",
                    "I will brainstorm possible solutions that could satisfy as many demands as possible without compromising my own goals and values.",
                    "I will communicate effectively and transparently with all parties, explaining the reasons behind my decisions and seeking their understanding and cooperation."
                ],
                understandImage: "https://firebasestorage.googleapis.com/v0/b/beworldclass-801f5.appspot.com/o/staticImages%2Fbalance.png?alt=media&token=060aad7b-ccfa-46a9-ac09-4730551105c0",
                understand: "<h4>Having a Balanced Approach to Satisfying Conflicting Demands</h4> <p><strong>A rephrased version:</strong> Try to meet the needs of different people who want different things in a fair and balanced way.</p> <p><strong>Description:</strong> Having a balanced approach to satisfying a range of interested parties with conflicting demands means being able to manage multiple needs or expectations from different groups of people. This requires understanding what each party wants, prioritizing those wants, and finding solutions that can meet as many of those needs as possible. It also requires good communication and negotiation skills to ensure that all parties understand and accept the decisions made.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Take on a role in a school project where you need to manage different expectations and needs from your team members, your teacher, and perhaps other students.</li> <li>Participate in a group activity (like a club or a sport) where you need to balance the needs and wants of your teammates, coach, and other stakeholders.</li> <li>Show how you negotiate between your friends when they want to do different activities.</li> <li>Document your approach to resolving a conflict between your siblings or family members.</li> </ul> </p>"
            },
            {
                id: 1234567890,
                index: 2,
                step: 1,
                icon: "chart-line",
                name: "measure the impact of what I do, and change my actions accordingly",
                improvements: [
                    "I will set clear goals and track my progress towards them using charts or journals.",
                    "I will reflect on my actions and identify the positive and negative impacts they have had.",
                    "I will seek feedback from others about the effect of my actions and consider their perspectives.",
                    "I will make adjustments to my actions based on what I learn, to better align with my goals or values."
                ],
                understand: "<h4>Measuring the Impact of What I Do and Changing My Actions Accordingly</h4> <p><strong>A rephrased version:</strong> Understanding how my actions affect things, and making changes if needed.</p> <p><strong>Description:</strong> Measuring the impact of what you do and changing your actions accordingly means being aware of how your actions affect others and the world around you. It includes setting goals, tracking your progress, reflecting on the effects of your actions, and making necessary changes. By practicing this characteristic, you can make more thoughtful decisions and take actions that are more aligned with your values and objectives.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Set a personal goal and track your progress in a journal or on a chart.</li> <li>Ask friends, family, or teachers for feedback on something you've done, and listen to their thoughts.</li> <li>Think about how your choices and actions have affected others and yourself, both positively and negatively.</li> <li>Make changes to your actions if you find that they aren't helping you reach your goal, or if they are having negative impacts.</li> <li>Create a visual representation, like a chart or diagram, to show how your actions are helping or hindering your progress towards a goal.</li> </ul> </p>"
            },
            {
                id: 4123445413,
                index: 3,
                step: 1,
                icon: "comments",
                name: "communicate effectively",
                improvements: [
                    "I will practice active listening by giving full attention to others when they are speaking and asking follow-up questions.",
                    "I will work on expressing my ideas clearly, using simple and concise language.",
                    "I will use appropriate body language, such as eye contact and gestures, to enhance my verbal communication.",
                    "I will practice communicating in different contexts, such as in group discussions, presentations, or written correspondence, and take pictures as evidence."
                ],
                understand: "<h4>Communicating Effectively</h4> <p><strong>A rephrased version:</strong> Being good at talking and listening helps you share your ideas and understand others.</p> <p><strong>Description:</strong> Communicating effectively means expressing your thoughts and ideas clearly, listening to others attentively, and using appropriate body language. It helps in building better relationships with friends, family, teachers, and others. It's not only about talking; it also includes writing, listening, and even non-verbal signals like facial expressions.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Practice speaking clearly and at a reasonable pace during conversations.</li> <li>Listen to others without interrupting and ask questions to show that you're interested.</li> <li>Use body language, such as smiling and nodding, to show that you're engaged in the conversation.</li> <li>Write a clear email or letter to a friend or teacher, making sure to organize your thoughts and use proper grammar.</li> <li>Make a short video or voice recording of yourself talking about a topic you're passionate about, focusing on clear pronunciation and expression.</li> </ul> </p>"
            },
            {
                id: 5748263519,
                index: 4,
                step: 2,
                icon: "handshake",
                name: "make sure the objectives of any activity I carry out are understood and accepted by the community I am serving",
                improvements: [
                    "I will identify the community I am serving and understand their needs and values.",
                    "I will communicate the objectives of my activities clearly and openly with the community, and actively listen to their feedback.",
                    "I will make adjustments to the objectives if needed, based on the community's feedback and needs.",
                    "I will provide evidence of the community's acceptance, such as signed agreements, photographs of community meetings, or documented conversations."
                ],
                understand: "<h4>Ensuring Objectives are Understood and Accepted by the Community</h4> <p><strong>A rephrased version:</strong> Making sure that the people you are helping agree with and understand what you are trying to do.</p> <p><strong>Description:</strong> Ensuring that the objectives of any activity are understood and accepted by the community means that you are not just doing something for people, but working with them. This involves talking to the community, understanding what they need and want, and making sure that they agree with what you are planning to do. It's a way of showing respect and making sure that your work is useful and welcome.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Talk to community members about your plans, and ask for their opinions and ideas.</li> <li>Show drafts or prototypes of what you are planning to do, and make changes based on community feedback.</li> <li>Work together with community members in planning and carrying out the activities.</li> <li>Document the process through photographs, videos, or written records to show how the community was involved and how they agreed to the objectives.</li> <li>Reflect on the process and think about what went well and what could be improved in the future.</li> </ul> </p>"
            },
            {
                id: 4123445413,
                index: 5,
                step: 2,
                icon: "users-cog",
                name: "research and understand what the community I am serving needs",
                improvements: [
                    "I will identify the community I am serving and list the key characteristics, interests, and needs.",
                    "I will conduct interviews or surveys with members of the community to understand their specific needs and preferences.",
                    "I will analyze existing data and research on the community to identify trends, challenges, and opportunities.",
                    "I will create a plan to address the needs of the community based on my research, and document my progress in a journal or report."
                ],
                understand: "<h4>Researching and Understanding the Community's Needs</h4> <p><strong>A rephrased version:</strong> Learning what the people in your community need and want by talking to them and studying information about them.</p> <p><strong>Description:</strong> Researching and understanding what the community you are serving needs involves learning about the people, their interests, and their challenges. This can be done by talking to them directly, conducting surveys, or looking at existing research. By understanding the community's needs, you can create plans to help them, whether it's through volunteering, organizing events, or supporting local businesses.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Talk to people in your community to learn about their needs, interests, and opinions.</li> <li>Conduct a survey or use social media to gather information from a wider group of people.</li> <li>Research online or in the library to find studies and data about your community's needs.</li> <li>Work with local organizations or leaders to help address the community's needs based on your research.</li> <li>Create a report or presentation that summarizes what you've learned and how you plan to help the community.</li> </ul> </p>"
            },
            {
                id: 5243245231,
                index: 6,
                step: 3,
                icon: "hands-helping",
                name: "am totally committed to and engaged with the community I am serving",
                improvements: [
                    "I will volunteer at a local community center or charity organization and contribute a few hours every week.",
                    "I will participate in community events, meetings, or gatherings to better understand the needs and interests of those I serve.",
                    "I will work on a community project, such as a community garden or cleanup, and take an active role in planning and execution.",
                    "I will engage with community members through social media or local forums to offer support, share information, and foster a sense of community."
                ],
                understand: "<h4>Being Totally Committed to and Engaged with the Community</h4> <p><strong>A rephrased version:</strong> Being dedicated and involved in helping and supporting the community where you live or work.</p> <p><strong>Description:</strong> Being committed to and engaged with the community means actively participating in community activities, understanding the needs of the people, and working to make a positive difference. It's about building relationships, showing care, and taking responsibility for the well-being of those around you.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Volunteer your time to local charities, schools, or other community organizations.</li> <li>Attend community meetings and events to understand what matters most to the people you are serving.</li> <li>Work on or lead a project that benefits the community, like organizing a park clean-up or creating a neighborhood watch.</li> <li>Connect with community members online or in person to share ideas, support each other, and create a sense of belonging and trust.</li> <li>Take pictures or keep a journal of your community involvement, reflecting on what you've learned and how you've made a difference.</li> </ul> </p>"
            },
            {
                id: 5123456789,
                index: 7,
                step: 3,
                icon: "users",
                name: "make sure the community I serve are able freely to share, and actively seek, opportunities to enhance their own competence, knowledge, and experience",
                improvements: [
                    "I will organize community meetings or workshops where members can share their skills and knowledge.",
                    "I will create an online platform or group where community members can post opportunities, such as courses, workshops, or mentorship programs.",
                    "I will actively participate in community learning events and encourage others to do the same.",
                    "I will provide resources such as books, online materials, or access to experts to help community members enhance their competence."
                ],
                understand: "<h4>Enhancing Community Competence, Knowledge, and Experience</h4> <p><strong>A rephrased version:</strong> Helping the people in your community to learn, grow, and get better at what they do.</p> <p><strong>Description:</strong> Making sure the community you serve can freely share and seek opportunities to grow means encouraging people to learn new things and get better at what they do. This includes creating spaces for people to share their skills, finding ways to help them learn, and encouraging them to take advantage of opportunities to grow.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Arrange community events where people can learn from each other.</li> <li>Create an online space where people can share opportunities and resources.</li> <li>Encourage people to take part in workshops, courses, or other learning opportunities.</li> <li>Provide resources such as books, videos, or connections to experts who can help people learn more.</li> <li>Act as a mentor or find mentors who can help others in the community develop their skills and knowledge.</li> </ul> </p>"
            },

        ]
    },
    {
        name: "Workplace",
        stage: "secondary",
        imageUrl: "https://i0.wp.com/www.worldclass-schools.org/wp-content/uploads/2020/11/WCS05-Workplace-480x480.jpg?resize=230%2C230&ssl=1",
        path: "workplace",
        color: "#0cbab9",
        motto: "I am the best candidate in my field for higher education and/or employment because I"
        , characteristics: [{
            id: 5273445563,
            index: 0,
            step: 0,
            icon: "briefcase",
            name: "have high quality work experience",
            improvements: [
                "I will seek opportunities to volunteer or assist in community events or projects to gain practical experience.",
                "I will practice professional behavior such as punctuality, responsibility, and communication by helping at home or taking on responsibilities at school.",
                "I will conduct informational interviews with adults in professions I am interested in to understand what their work involves.",
                "I will create a 'work experience journal' to record what I've learned from any formal or informal work experiences, including tasks completed, skills learned, and reflections."
            ],
            understand: "<h4>Having High Quality Work Experience</h4> <p><strong>A rephrased version:</strong> Gaining valuable experience by doing jobs or tasks, even if they are not formal jobs.</p> <p><strong>Description:</strong> Having high quality work experience doesn't always mean having a formal job. It means gaining skills and understanding through doing tasks, volunteering, or helping others. This can include helping at home, volunteering in the community, or learning about different professions. By engaging in these activities, you can develop essential skills like teamwork, problem-solving, and responsibility.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Help with family chores or take on a special project at home, like organizing a space or cooking a meal.</li> <li>Volunteer at a community event or with a local charity to gain experience working with others.</li> <li>Ask adults about their jobs and what they like and dislike about them to learn about different professions.</li> <li>Keep a journal about your experiences, noting what you did, what you learned, and how you felt about it.</li> <li>Show professionalism by being on time, following through with tasks, and communicating well.</li> </ul> </p>"
        },
        {
            id: 6385445674,
            index: 1,
            step: 0,
            icon: "city",
            name: "have involvement in partnerships with business and public organisations, locally, nationally and internationally",
            improvements: [
                "I will explore and learn about local businesses and organizations in my community by visiting them or researching online.",
                "I will participate in community projects or school clubs that partner with local businesses or public organizations.",
                "I will engage with national and international connections through online platforms that connect me with peers in other places.",
                "I will create a project or presentation about a company or organization I admire, exploring how it connects with others locally, nationally, or internationally."
            ],
            understand: `
            <h4>Involvement in Partnerships with Business and Public Organisations</h4>
            <p><strong>A rephrased version:</strong> Joining and working together with different groups, like local businesses, schools, and community helpers, in your area, across your country, and even in other countries.</p>
            <p><strong>What is a Partnership?</strong> A partnership is when two or more groups or people work together towards a common goal. It's like being on a team where everyone helps each other!</p>
            <p><strong>Description:</strong> This might sound like a big idea, but it's something you can be a part of in many ways. Let's break it down:</p>
            <ul>
              <li><strong>Locally:</strong> In your town or city, you might be part of a project with a local store or a charity, or join your school in a project with another nearby school.</li>
              <li><strong>Nationally:</strong> Across your country, you might be involved in an event where schools from different parts of the country work together, or be part of a project where your school helps a national charity.</li>
              <li><strong>Internationally:</strong> This means connecting with groups in other countries. Your school might partner with a school in another country, or be involved with businesses or charities that operate internationally.</li>
            </ul>
            <p>By exploring these connections, you can learn a lot about how the world works, and how people and groups help each other and work together.</p>
            <p><strong>Suggestions and Examples:</strong> Here are some ways you might show this characteristic:</p>
            <ul>
              <li>Join a school project or club that works with other schools, businesses, or groups in your community, like a recycling project or helping a local charity.</li>
              <li>Participate in an event where your school is working with schools from other parts of the country, like a national competition or fundraiser.</li>
              <li>Work on a project about how businesses or groups in your area connect with others around the world, like how a local company sells products in other countries.</li>
              <li>Be part of a school partnership with an international school or organization, learning about their culture and how you can work together.</li>
            </ul>
            <p>These are all ways to understand how businesses and organizations work together, close to home and far away. It might be exciting to see how connected everything is, and how you can be part of it too!</p>"
            `
        },
        {
            id: 6394665738,
            index: 2,
            step: 1,
            icon: "user-friends",
            name: "have worked with, and learned from individuals, among the best in their field, with appropriate local, national, and international experience.",
            improvements: [
                "I will identify and reach out to local mentors or experienced individuals in areas I'm interested in, such as teachers, community leaders, or skilled family members.",
                "I will watch educational videos or documentaries featuring experts in different fields to learn from their knowledge and experience.",
                "I will participate in clubs or groups focused on my interests, where I might meet and learn from experienced individuals.",
                "I will reflect on what I've learned from others by keeping a 'learning journal', recording insights, and applying them to my own projects."
            ],
            understand: "<h4>Working with and Learning from Experienced Individuals</h4> <p><strong>A rephrased version:</strong> Learning from people who are really good at what they do, whether in your town, your country, or around the world.</p> <p><strong>Description:</strong> You don't have to be a professional to learn from those who are skilled in their fields. You can learn from experienced people around you, like teachers, family members, or community leaders. You can also learn from videos, documentaries, and online resources featuring experts. By doing this, you get to understand different perspectives and gain valuable insights and skills.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Ask a teacher or family member who is good at something to show you how they do it.</li> <li>Watch videos of professionals talking about their work or demonstrating their skills.</li> <li>Join a club or group that focuses on something you love, like art, sports, or science, where you can learn from others.</li> <li>Keep a journal about what you learn from others, and how you might use that knowledge yourself.</li> <li>Attend local community events or workshops where professionals might be sharing their knowledge.</li> </ul> </p>"
        },
        {
            id: 4123445413,
            index: 3,
            step: 1,
            icon: "users",
            name: "have exceptionally well developed skills of collaboration",
            improvements: [
                "I will participate in group projects and actively contribute ideas, ensuring everyone's voice is heard.",
                "I will practice active listening by giving full attention to my team members and acknowledging their opinions.",
                "I will set clear roles and responsibilities within my group to foster a sense of teamwork and shared goals.",
                "I will provide constructive feedback to my peers and accept feedback from others to continuously improve our collaboration."
            ],
            understand: "<h4>Having Exceptionally Well Developed Skills of Collaboration</h4> <p><strong>A rephrased version:</strong> Working well with others helps you achieve more together.</p> <p><strong>Description:</strong> Having exceptionally well-developed skills of collaboration means working effectively and positively with others. This includes listening actively, sharing ideas, taking on different roles, and giving and receiving feedback. By practicing collaboration, you can develop better communication skills, build trust, and achieve shared goals more efficiently.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Join a group project at school or in your community and take an active part in planning and decision-making.</li> <li>Listen to others' ideas and ask questions to make sure you understand their perspectives.</li> <li>Work with your team to set clear goals and assign tasks that suit each person's strengths.</li> <li>Be open to receiving feedback from others and offer your own feedback in a respectful and constructive way.</li> <li>Reflect on what went well in your collaboration and what you can improve in the future.</li> </ul> </p>"
        },
        {
            id: 7182735921,
            index: 4,
            step: 2,
            icon: "briefcase",
            name: "am highly employable",
            improvements: [
                "I will create or update my professional resume, highlighting my achievements and experiences relevant to the field I wish to enter.",
                "I will practice my interview skills with a friend or family member, focusing on expressing my strengths and enthusiasm for the role.",
                "I will research the industry I want to enter, and network with professionals to gain insights and connections in the field."
            ],
            understand: "<h4>Being Highly Employable</h4> <p><strong>A rephrased version:</strong> Being ready to get a job by having the right skills, experience, and attitude.</p> <p><strong>Description:</strong> Being highly employable means having a combination of skills, experiences, and personal attributes that make you an attractive candidate for employers. This includes having both hard skills (like technical abilities) and soft skills (like communication and teamwork), as well as a positive attitude towards work.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Take on projects or volunteer opportunities that allow you to develop skills relevant to your desired career.</li> <li>Seek feedback on your resume and interview skills from teachers, mentors, or professionals in the field.</li> <li>Build relationships with people in the industry you want to work in, and ask them for insights and advice.</li> <li>Set clear career goals and create a plan to achieve them, including identifying specific jobs or companies you want to target.</li> <li>Stay informed about trends and changes in the job market, and be ready to adapt and learn new skills as needed.</li> </ul> </p>"
        },
        {
            id: 5236874293,
            index: 5,
            step: 2,
            icon: "language",
            name: "have developed skills in languages and/or an understanding of global issues",
            improvements: [
                "I will commit to learning and practicing a new language by using language apps, online resources, or joining a language club.",
                "I will follow global news from diverse sources to understand different perspectives on international issues.",
                "I will engage with cultural communities in my area or online to experience different languages and cultures first-hand.",
                "I will participate in discussions or write essays about global issues to deepen my understanding and develop my ability to articulate thoughts."
            ],
            understand: "<h4>Developing Skills in Languages and Understanding Global Issues</h4> <p><strong>A rephrased version:</strong> Learning new languages and understanding issues around the world can make you more knowledgeable and connected.</p> <p><strong>Description:</strong> Developing skills in languages means learning and practicing new languages. Understanding global issues involves being aware of and thinking about problems and events that affect people all over the world. This includes cultural understanding, political awareness, and recognizing the interconnectedness of our global society. By learning languages and understanding global issues, you can connect with more people and better understand the world you live in.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Start learning a new language through a language app, school course, or community class.</li> <li>Read international news and follow various global issues through reputable sources to understand different viewpoints.</li> <li>Attend cultural events, or engage with online forums to experience different cultures and languages.</li> <li>Discuss or write about global issues with friends, family, or in a school project to further deepen your understanding.</li> </ul> </p>"
        }

        ]
    },
    {
        name: "Knowledge and understanding",
        stage: "secondary",
        imageUrl: "https://i0.wp.com/www.worldclass-schools.org/wp-content/uploads/2020/11/WCS05-Knowledge-480x480.jpg?resize=230%2C230&ssl=1",
        path: "knowledge"
        , color: "#2c7ec2",
        motto: "I am highly literate scientifically, mathematically, technologically and culturally because I"
        , characteristics: [
            {
                id: 6872934143,
                index: 0,
                step: 0,
                icon: "flask",
                name: "have an awareness of how science, mathematics and technology shape our environment",
                improvements: [
                    "I will explore and investigate the impact of technology in my daily life, such as using electronic devices, energy-saving appliances, and the Internet.",
                    "I will learn about scientific concepts that influence the environment, such as weather patterns, conservation, and renewable energy.",
                    "I will examine how mathematics is used in engineering and technology to design and build structures like bridges, roads, and buildings.",
                    "I will create a scrapbook or digital journal containing examples of science, mathematics, and technology in my community, including photographs, diagrams, and reflections."
                ],
                understand: "<h4>Understanding How Science, Mathematics, and Technology Shape Our Environment</h4> <p><strong>A rephrased version:</strong> Recognizing how things like science, numbers, and gadgets affect the world around us.</p> <p><strong>Description:</strong> Having an awareness of how science, mathematics, and technology shape our environment means understanding how these subjects affect the way we live, work, and interact with the world. It includes recognizing how scientific principles guide nature, how mathematics builds structures, and how technology enhances our daily lives.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Explore how scientific principles like gravity or photosynthesis work in nature.</li> <li>Investigate how math helps in designing things like video games or skyscrapers.</li> <li>Look at how technology, such as smartphones or solar panels, has changed how we live and work.</li> <li>Visit a science museum or engage in hands-on science experiments to see these principles in action.</li> <li>Take pictures of scientific, mathematical, or technological objects in your surroundings and explain their importance in a journal.</li> </ul> </p>"
            },
            {
                id: 4123445413,
                index: 1,
                step: 0,
                icon: "calculator",
                name: "make well-founded mathematical judgements",
                improvements: [
                    "I will solve real-world problems using mathematics, such as calculating distances, budgets, or measurements for a project.",
                    "I will analyze data or statistics in everyday contexts, such as comparing prices or evaluating sports performance.",
                    "I will practice explaining my reasoning when solving problems, including the steps I took and why I chose them.",
                    "I will create a portfolio of mathematical solutions to everyday problems, like planning a trip, organizing an event, or building a model, and include photographs and explanations."
                ],
                understand: "<h4>Making Well-Founded Mathematical Judgements</h4> <p><strong>A rephrased version:</strong> Using what you know about math to make smart decisions and solve problems in everyday life.</p> <p><strong>Description:</strong> Making well-founded mathematical judgements means using math to think critically and solve problems in the real world. This includes using mathematical concepts and skills to calculate, analyze, and make decisions about things like money, time, distance, or other everyday situations. By practicing this skill, you can make more informed decisions and better understand the world around you.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Calculate how much paint you'll need for a room by measuring the walls and using math to figure out the area.</li> <li>Analyze a sports game's statistics to determine which player performed best.</li> <li>Use math to create a budget for a school project or family outing, including considerations for expenses like transportation, food, and entertainment.</li> <li>Explain your reasoning in a math problem by showing each step and explaining why you chose that method.</li> <li>Take pictures of your mathematical solutions in real-world scenarios and include them in your portfolio.</li> </ul> </p>"
            },
            {
                id: 4123445414,
                index: 2,
                step: 1,
                icon: "globe",
                name: "identify and understand the role that mathematics plays in the world",
                improvements: [
                    "I will explore different careers and fields that rely on mathematics, such as engineering, finance, or medicine.",
                    "I will investigate how mathematics is used in my local community, such as in architecture, city planning, or transportation.",
                    "I will create a project or presentation that shows how mathematics is used in various aspects of daily life, including shopping, cooking, or sports.",
                    "I will take photographs of real-world examples where mathematics is at play and write an explanation or reflection on each one."
                ],
                understand: "<h4>Identifying and Understanding the Role that Mathematics Plays in the World</h4> <p><strong>A rephrased version:</strong> Discovering how math is used everywhere around us, in jobs, buildings, shopping, and more.</p> <p><strong>Description:</strong> Identifying and understanding the role that mathematics plays in the world means recognizing how math is used in many parts of everyday life. This includes how math is used in different jobs, how it helps build and design things, and how we use it for simple tasks like cooking or shopping. By exploring this, you can see how important math is and how it helps make the world work.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Interview a local engineer or architect to learn how they use math in their work.</li> <li>Explore how math helps your favorite sports team track performance and make decisions.</li> <li>Calculate discounts and sales tax during a shopping trip and explain how you did it.</li> <li>Create a poster or slideshow that shows different ways math is used around the world, such as in building bridges, forecasting weather, or managing money.</li> <li>Capture photos of buildings, roads, or other examples where math has been used in design and construction, and reflect on how math was involved.</li> </ul> </p>"
            },
            {
                id: 1234567890,
                index: 3,
                step: 1,
                icon: "robot",
                name: "successfully take the initiative to develop innovative school-based or external technological systems and processes",
                improvements: [
                    "I will find a task at school or home that's currently done on paper and figure out how it could be done using a computer or mobile device, like tracking homework or creating a family schedule.",
                    "I will ask a teacher, parent, or other adult to help me find an online tool or app that can help me with my schoolwork or a home task.",
                    "I will learn how to use a new technological tool that can help me be more organized, like a note-taking app or a digital calendar.",
                    "I will help my family or friends with a technological solution to make something easier, such as setting up shared online grocery lists or family reminders.",
                    "I will create a digital presentation for a class project or a personal interest, adding multimedia elements to make it engaging."
                ],
                understand: "<h4>Taking the Initiative to Create Innovative Technological Solutions</h4> <p><strong>A rephrased version:</strong> Finding new ways to use technology to make things easier at school or at home.</p> <p><strong>Description:</strong> This means looking at everyday tasks or problems at school or at home and thinking about how technology could make them simpler or more fun. You don't need to be a computer expert; you just need to be creative and think about how the technology you already use could be applied in new ways.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Use an online system or app to keep track of your homework, projects, and deadlines, instead of writing them down on paper.</li> <li>Help your family create a shared online calendar to keep track of everyone's activities and appointments.</li> <li>Find a way to organize your school notes electronically so you can search and find what you need quickly.</li> <li>Use technology to make a presentation for class, adding pictures, videos, or music to make it more interesting.</li> <li>Work with your school or community to set up a digital bulletin board where students can share news, ideas, and ask for help with schoolwork.</li> </ul> </p>"
            },
            {
                id: 5236413251,
                index: 4,
                step: 2,
                icon: "flask",
                name: "am willing to engage in science-, mathematical- and technology-related issues, as a reflective citizen",
                improvements: [
                    "I will explore different scientific phenomena by conducting simple experiments at home, such as growing a plant or observing the weather.",
                    "I will practice mathematical skills by solving real-world problems like budgeting my weekly allowance or measuring ingredients for cooking.",
                    "I will explore technology by learning how everyday gadgets work and maybe even try to build a simple circuit or program a basic game.",
                    "I will stay informed about current events in science and technology, and reflect on how they affect my community and the world."
                ],
                understand: "<h4>Engaging in Science, Math, and Technology as a Reflective Citizen</h4> <p><strong>A rephrased version:</strong> Being curious about science, math, and technology, and thinking about how they relate to your life and the world.</p> <p><strong>Description:</strong> Engaging in science, mathematical- and technology-related issues means being interested in these subjects and thinking about how they connect to your everyday life and the world around you. This includes exploring scientific ideas, solving mathematical problems, understanding how technology works, and reflecting on how these subjects shape our society.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Conduct simple science experiments at home, like mixing baking soda and vinegar to observe the reaction, or tracking the phases of the moon.</li> <li>Use math to solve problems in your daily life, such as calculating the total cost of items you want to buy, or figuring out how long it will take to save up for something special.</li> <li>Explore technology by learning how things like your smartphone or computer work, or by creating a simple coding project.</li> <li>Discuss with family or friends about recent scientific discoveries or technological advancements, and reflect on how they might impact your life and the world.</li> <li>Read books or watch videos about famous scientists, mathematicians, or technologists, and think about how their work has changed our lives.</li> </ul> </p>"
            },
            {
                id: 5678123490,
                index: 5,
                step: 2,
                icon: "book-open",
                name: "have been exposed to a breadth of texts which develop my general knowledge",
                improvements: [
                    "I will explore different categories and genres of books, including history, science, art, and culture.",
                    "I will read newspapers, magazines, and online articles to stay updated on current events and world affairs.",
                    "I will join a reading club or discussion group to share ideas and gain different perspectives on the texts I read.",
                    "I will create a reading log to document the key insights and knowledge gained from each text."
                ],
                understand: "<h4>Being Exposed to a Breadth of Texts to Develop General Knowledge</h4> <p><strong>A rephrased version:</strong> Reading different kinds of books and articles can help you learn about many topics and understand the world better.</p> <p><strong>Description:</strong> Being exposed to a breadth of texts means reading a wide variety of materials from different genres, cultures, and subjects. By reading broadly, you can develop your general knowledge and become more aware of different ideas, perspectives, and information. This habit can help you become a more well-rounded person and contribute to your education and personal growth.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Choose books from different subjects like science, history, art, or literature.</li> <li>Read newspapers or online articles to keep up with current events.</li> <li>Join a library or reading group to find new reading material and discuss ideas with others.</li> <li>Make a habit of noting down interesting facts or concepts you come across while reading.</li> <li>Consider reading works from different cultures or translated works to gain a broader perspective.</li> </ul> </p>"
            },
            {
                id: 5172839672,
                index: 6,
                step: 2,
                icon: "globe",
                name: "appreciate the systems of beliefs, values, attitudes, customs, institutions and social relations relevant to global developments and am able treat all people respectfully and in a suitable manner appropriate to their culture",
                improvements: [
                    "I will learn about different cultures and their traditions, beliefs, and values.",
                    "I will research on global events and how different cultures respond to them.",
                    "I will engage in respectful discussions with individuals from diverse cultural backgrounds.",
                    "I will reflect on my own biases and stereotypes, and work to overcome them.",
                    "I will keep a journal documenting my understanding of different cultures and global developments."
                ],
                understand: "<h4>Appreciating Cultures and Treating People Respectfully</h4> <p><strong>A rephrased version:</strong> Respect and understand people's beliefs and customs, and treat everyone appropriately, no matter where they're from.</p> <p><strong>Description:</strong> Appreciating different cultures and treating people with respect means understanding and valuing the diverse beliefs, values, customs, and social structures that exist around the world. It also involves behaving in a manner that is considerate and appropriate to people's cultural backgrounds. By practicing this skill, you can develop a global perspective, foster mutual respect and understanding, and contribute to a more inclusive and harmonious society.</p> <p><strong>Suggestions and Examples:</strong> To show this characteristic, you can:</p> <ul> <li>Read books, watch films, or attend events that explore different cultures and global issues.</li> <li>Engage in discussions with people from diverse backgrounds, listening to their views and experiences with an open mind.</li> <li>Reflect on your own beliefs and attitudes, and challenge any stereotypes or biases you may have.</li> <li>Research current global events and consider how they may impact people from different cultural backgrounds.</li> <li>Write reflections on your learnings about different cultures in a journal, noting any new insights and how your understanding has changed over time.</li> </ul> </p > "
            }



        ]
    },
    {
        name: "I Am",
        stage: "primary",
        imageUrl: "https://www.worldclass-schools.org/wp-content/uploads/2020/12/WCS06-I-Am-transparent-480x480.png",
        path: "iAm"
        , motto: "I am..."
        , characteristics: [{
            id: 10,
            index: 0,
            step: 0,
            icon: "heart",
            name: "Caring",
            improvements: [
                "I will listen and help when a classmate talks about a problem, like if they can't understand their homework.",
                "I will include everyone in games and activities, making sure no one feels left out.",
                "I will use words that make people feel good, like complimenting a friend on their artwork or project.",
                "I will take care of our classroom and school, like helping to organize books or clean up after a project."
            ],
            understand: "<h4>Being Caring</h4> <p><strong>Description:</strong> Caring means showing kindness and helping others. It's about being a good friend and taking care of the places and things around you.</p> <p><strong>Writing About Your Caring Actions:</strong> Think about times when you've been caring. You can write about:</p> <ul> <li>A time you helped someone who was feeling sad or had a problem.</li> <li>When you made sure everyone was included in a game or group activity.</li> <li>How you said something nice to make a friend feel better.</li> <li>A moment you helped take care of your classroom, like tidying up or looking after class pets or plants.</li> </ul> "
        },
        {
            id: 11,
            index: 1,
            step: 0,
            icon: "balance-scale",
            name: "Honest",
            improvements: [
                "I will tell the truth when a teacher asks about something that happened, even if it's hard.",
                "I will admit to my parents or guardians if I forget to do my homework or make a mistake.",
                "I will return things I find, like a lost book or toy, to their rightful owner.",
                "I will speak up if I see someone being treated unfairly or if something wrong is happening."
            ],
            understand: "<h4>Being Honest</h4> <p><strong>Description:</strong> Honesty means telling the truth and being fair. It's about being trustworthy and doing the right thing, even when it's difficult.</p> <p><strong>Writing About Your Honesty:</strong> Think of a time when you were honest. You can write about:</p> <ul> <li>A moment you told the truth, even though you were worried about getting in trouble.</li> <li>When you admitted a mistake you made and how you fixed it.</li> <li>A time you found something and gave it back to the person who lost it.</li> <li>How you spoke up about something wrong or unfair.</li> </ul> "
        },
        {
            id: 12,
            index: 2,
            step: 0,
            icon: "search",
            name: "Curious",
            improvements: [
                "I will ask questions in class when I don't understand something or want to know more.",
                "I will read a book or watch a documentary on a topic I'm interested in and share what I learned with my class.",
                "I will explore a new hobby or activity, like learning to play a musical instrument or trying a new sport.",
                "I will visit a museum, library, or historical site and write about something new I discovered."
            ],
            understand: "<h4>Being Curious</h4> <p><strong>Description:</strong> Curiosity means wanting to learn and discover new things. It's about asking questions, exploring, and not being afraid to try new experiences.</p> <p><strong>Writing About Your Curiosity:</strong> Think of a time when you explored something new or asked questions to learn more. You can write about:</p> <ul> <li>A question you asked in class that helped you understand a topic better.</li> <li>A book, article, or show that you explored and what interesting thing you learned from it.</li> <li>A new activity or hobby you tried and what you discovered about it.</li> <li>A place you visited, like a museum or park, and what you found interesting there.</li> </ul>"
        },
        {
            id: 13,
            index: 3,
            step: 1,
            icon: "users",
            name: "A Good Friend",
            improvements: [
                "I will listen to my friends when they talk and show that I understand their feelings.",
                "I will invite someone who is alone to play or work with me in class.",
                "I will stand up for my friends if someone is being unkind to them.",
                "I will celebrate my friends' successes and support them when they are sad or struggling."
            ],
            understand: "<h4>Being a Good Friend</h4> <p><strong>Description:</strong> Being a good friend means caring for others, listening to them, and being there for them. It's about being kind, supportive, and loyal.</p> <p><strong>Writing About Being a Good Friend:</strong> Think of a time when you were a good friend. You can write about:</p> <ul> <li>A time you listened to a friend and helped them with a problem.</li> <li>When you included someone who was feeling left out.</li> <li>How you helped a friend who was being treated unfairly.</li> <li>A moment you celebrated a friend's achievement or helped them when they were upset.</li> </ul>"
        },
        {
            id: 14,
            index: 4,
            step: 1,
            icon: "brain",
            name: "Good at Understanding How I Feel About Things",
            improvements: [
                "I will take a moment to think about how I feel after something happens, like if I win a game or have a disagreement.",
                "I will talk to someone I trust, like a teacher or family member, about my feelings, especially if I'm very happy, sad, or angry.",
                "I will keep a diary or draw pictures to express my feelings and thoughts about different events or days.",
                "I will practice calming techniques, like deep breathing or counting to ten, when I feel upset or angry."
            ],
            understand: "<h4>Understanding Your Feelings</h4> <p><strong>Description:</strong> Understanding how you feel about things means knowing your own emotions. It's about noticing how different things make you feel and finding ways to express and deal with those feelings.</p> <p><strong>Writing About Understanding Your Feelings:</strong> Think of a time when you recognized and thought about your feelings. You can write about:</p> <ul> <li>A moment you felt really happy, sad, or angry and what caused it.</li> <li>How you shared your feelings with someone else or wrote them down.</li> <li>What you do to calm down when you're feeling upset or angry.</li> </ul>"
        },
        {
            id: 15,
            index: 5,
            step: 1,
            icon: "hand-holding-heart",
            name: "Good at Understanding How Other People Feel About Things",
            improvements: [
                "I will notice when someone looks happy or sad and ask them about it in a kind way.",
                "I will think about how my actions or words might make others feel before I speak or act.",
                "I will offer to help or comfort a classmate or friend if they seem upset or worried.",
                "I will try to understand someone's point of view when we disagree, and not just think about my own."
            ],
            understand: "<h4>Understanding Others' Feelings</h4> <p><strong>Description:</strong> Understanding how other people feel means noticing and caring about their emotions. It's about listening, being kind, and trying to see things from their perspective.</p> <p><strong>Writing About Understanding Others' Feelings:</strong> Think of a time when you understood how someone else was feeling. You can write about:</p> <ul> <li>A time you noticed someone was feeling happy or sad and talked to them about it.</li> <li>How you thought about someone else's feelings before doing or saying something.</li> <li>When you helped or comforted someone who was upset.</li> <li>An instance where you tried to understand another person's view during a disagreement.</li> </ul>"
        },
        {
            id: 16,
            index: 6,
            step: 2,
            icon: "sync-alt",
            name: "Good at Coping with Change",
            improvements: [
                "I will stay calm and positive when something changes, like a new teacher or a different schedule.",
                "I will talk about my feelings when a big change happens, like moving to a new school or a new class.",
                "I will try to find the good in new situations, like making new friends or learning something interesting.",
                "I will ask for help or advice when I'm finding it hard to deal with a change."
            ],
            understand: "<h4>Coping with Change</h4> <p><strong>Description:</strong> Being good at coping with change means being able to adjust when things are different. It's about staying calm, looking for the positives, and asking for help when you need it.</p> <p><strong>Writing About Coping with Change:</strong> Think of a time when something changed in your life. You can write about:</p> <ul> <li>A change that happened and how you stayed positive.</li> <li>How you talked about your feelings during a big change.</li> <li>What you learned or enjoyed in a new situation.</li> <li>When you asked for help or advice to deal with a change.</li> </ul>"
        },
        {
            id: 17,
            index: 7,
            step: 2,
            icon: "handshake",
            name: "Helpful",
            improvements: [
                "I will offer to help my teacher with tasks like organizing materials or helping to clean up after a class activity.",
                "I will look for ways to help at home, like setting the table or helping with chores without being asked.",
                "I will assist classmates who are struggling with their work or need help understanding something.",
                "I will volunteer for school or community activities that need extra hands or help."
            ],
            understand: "<h4>Being Helpful</h4> <p><strong>Description:</strong> Being helpful means doing things to make life easier for others. It's about offering your time and effort to assist others without waiting to be asked.</p> <p><strong>Writing About Being Helpful:</strong> Think of a time when you were helpful. You can write about:</p> <ul> <li>A way you helped your teacher or a classmate in school.</li> <li>Something you did at home to help your family.</li> <li>How you assisted a friend or a classmate with their work or a problem.</li> <li>Any activity where you volunteered to help in school or your community.</li> </ul>"
        },
        {
            id: 18,
            index: 8,
            step: 2,
            icon: "lightbulb",
            name: "Good at Thinking",
            improvements: [
                "I will try to solve problems on my own before asking for help, like figuring out a difficult homework question.",
                "I will brainstorm different ways to do a task or solve a problem, and choose the best one.",
                "I will ask 'why' or 'how' questions in class to understand topics better and think deeper.",
                "I will spend time thinking about interesting topics or ideas and share my thoughts with others."
            ],
            understand: "<h4>Being Good at Thinking</h4> <p><strong>Description:</strong> Being good at thinking means using your mind to solve problems, ask questions, and come up with new ideas. It's about being creative and curious, and not being afraid to think differently.</p> <p><strong>Writing About Being Good at Thinking:</strong> Think of a time when you used your thinking to solve a problem or come up with a creative idea. You can write about:</p> <ul> <li>How you solved a difficult problem on your own or in a new way.</li> <li>When you asked questions in class that helped you understand something better.</li> <li>An interesting idea or topic you thought about and discussed with others.</li> </ul>"
        },
        {
            id: 19,
            index: 9,
            step: 2 ,
            icon: "ear-listen",
            name: "Good at Listening",
            improvements: [
                "I will focus on the person speaking, not interrupting them, and show that I'm listening by nodding or smiling.",
                "I will remember what was said in class or at home and follow instructions or answer questions about it.",
                "I will listen to my friends' ideas and feelings, and respond to them in a thoughtful way.",
                "I will ask questions if I don't understand something and listen carefully to the explanations."
            ],
            understand: "<h4>Being Good at Listening</h4> <p><strong>Description:</strong> Being good at listening means paying attention to what others are saying. It's about understanding their words and feelings, and showing you hear them.</p> <p><strong>Writing About Being Good at Listening:</strong> Think of a time when you listened really well. You can write about:</p> <ul> <li>A moment you paid close attention to a teacher or family member and followed their instructions or advice.</li> <li>When you listened to a friend share their thoughts or feelings and how you responded.</li> <li>How you asked questions to understand something better and what you learned from listening to the answers.</li> </ul>"
        },
        {
            id: 5678901234,
            index: 11,
            step: 3,
            icon: "comments",
            name: "Good at Communicating",
            improvements: [
                "I will speak clearly and share my ideas in class discussions or group projects.",
                "I will write messages or stories that are easy to understand, using words that everyone knows.",
                "I will use body language, like eye contact and gestures, to show my feelings and ideas when I talk.",
                "I will listen to others and respond in a way that shows I understand what they're saying."
            ],
            understand: "<h4>Being Good at Communicating</h4> <p><strong>Description:</strong> Being good at communicating means sharing your thoughts and ideas in a way that others can understand. It's about speaking, writing, and using your body to express yourself clearly.</p> <p><strong>Writing About Being Good at Communicating:</strong> Think of a time when you communicated well. You can write about:</p> <ul> <li>A time you explained something in class and your classmates understood it.</li> <li>When you wrote a story or message that was clear and interesting.</li> <li>How you used body language, like hand gestures or facial expressions, to add to your words.</li> <li>A conversation where you listened and then responded in a way that showed you understood.</li> </ul>"
        },
        {
            id: 6789012345,
            index: 12,
            step: 3,
            icon: "people-arrows",
            name: "Good at Working with Others and On My Own",
            improvements: [
                "I will contribute to group projects by sharing my ideas and listening to others.",
                "I will complete my individual tasks, like homework or classwork, responsibly and on time.",
                "I will ask for help when needed in a group and offer help to others when I can.",
                "I will find a quiet place to work when I need to concentrate on my own tasks."
            ],
            understand: "<h4>Being Good at Working with Others and On My Own</h4> <p><strong>Description:</strong> Being good at this means you can work well in a team and also do tasks by yourself. It's about cooperating with others and being responsible when working alone.</p> <p><strong>Writing About Working with Others and On Your Own:</strong> Think of times when you worked well with others and times you did a good job on your own. You can write about:</p> <ul> <li>A group project where you shared ideas and helped make something great together.</li> <li>An individual task you completed well, like a challenging piece of homework or a creative project.</li> <li>How you asked for or gave help in a team.</li> <li>When you found a good way to focus and work on your own.</li> </ul>"
        },
        {
            id: 7890123456,
            index: 13,
            step: 3,
            icon: "star",
            name: "The Best I Can Be",
            improvements: [
                "I will set a personal goal for myself in a subject I find challenging and work steadily to achieve it.",
                "I will practice a skill or hobby regularly, like playing a musical instrument or a sport, to improve at it.",
                "I will ask for feedback on my work and use it to make positive changes and grow.",
                "I will celebrate my achievements, big or small, and recognize the effort I put in to reach them."
            ],
            understand: "<h4>Being The Best You Can Be</h4> <p><strong>Description:</strong> Being the best you can be means always trying hard and looking for ways to do better. It's about setting goals, practicing, learning from feedback, and being proud of what you achieve.</p> <p><strong>Writing About Being The Best You Can Be:</strong> Think of a time when you really tried your best. You can write about:</p> <ul> <li>A goal you set for yourself and how you worked towards it.</li> <li>A skill or hobby you practiced regularly and improved at.</li> <li>How you used feedback to get better at something.</li> <li>An achievement you are proud of and the effort it took to get there.</li> </ul>"
        },
        {
            id: 8901234567,
            index: 14,
            step: 3,
            icon: "clipboard-list",
            name: "Good at Planning",
            improvements: [
                "I will plan my homework or projects by breaking them into smaller steps and deciding when to do each part.",
                "I will create a simple to-do list for my daily tasks, both at school and at home.",
                "I will keep my school supplies and workspace organized to help me stay on track with my tasks.",
                "I will prepare for the next day by packing my school bag and laying out my clothes the night before."
            ],
            understand: "<h4>Being Good at Planning</h4> <p><strong>Description:</strong> Being good at planning means thinking ahead and organizing things in a way that helps you achieve your goals. It's about making lists, setting deadlines, and keeping things in order.</p> <p><strong>Writing About Being Good at Planning:</strong> Think of a time when you planned something well. You can write about:</p> <ul> <li>How you organized a project or homework into steps and followed your plan.</li> <li>A to-do list you made and how it helped you complete your tasks.</li> <li>Ways you keep your things organized at school or at home.</li> <li>How preparing the night before helped you be ready for the next day.</li> </ul>"
        },
        {
            id: 9012345678,
            index: 15,
            step: 3,
            icon: "medal",
            name: "An Expert",
            improvements: [
                "I will choose a subject or activity I am passionate about and learn more about it, like space, a sport, or a type of art.",
                "I will practice regularly to improve my skills in my chosen area, setting aside time each week for it.",
                "I will share my knowledge or skills with others, like giving a presentation to the class or teaching a friend something new.",
                "I will seek out new information and resources, like books or websites, to expand my understanding and abilities."
            ],
            understand: "<h4>Being An Expert</h4> <p><strong>Description:</strong> Being an expert means having special knowledge or skills in a certain area. It's about learning, practicing, and sharing what you know with others.</p> <p><strong>Writing About Being An Expert:</strong> Think of an area where you have good skills or knowledge. You can write about:</p> <ul> <li>What you are passionate about and how you learned more about it.</li> <li>How you practice and improve your skills in this area.</li> <li>A time you shared your knowledge or skills with others.</li> <li>New things you have learned recently about your chosen area.</li> </ul>"
        },
        {
            id: 123456789,
            index: 16,
            step: 4,
            icon: "check-circle",
            name: "Reliable",
            improvements: [
                "I will complete my school assignments and chores at home on time and to the best of my ability.",
                "I will keep promises I make to my friends, teachers, and family, like returning a borrowed book or being on time.",
                "I will be consistent in my daily routines, like doing my homework before playtime and preparing for school every morning.",
                "I will communicate clearly if I cannot do something I promised, explaining why and trying to find a solution."
            ],
            understand: "<h4>Being Reliable</h4> <p><strong>Description:</strong> Being reliable means people can trust you to do what you say you will do. It's about being responsible, keeping your promises, and being consistent in what you do.</p> <p><strong>Writing About Being Reliable:</strong> Think of a time when you were reliable. You can write about:</p> <ul> <li>A task or assignment you completed well and on time.</li> <li>A promise you kept to someone.</li> <li>Your daily routine that shows you are consistent and dependable.</li> <li>How you handled a situation when you couldn't keep a promise or commitment.</li> </ul>"
        },
        {
            id: 1234567890,
            index: 17,
            step: 4,
            icon: "lightbulb",
            name: "Good at Problem Solving and Coming Up with Solutions",
            improvements: [
                "I will approach challenges calmly, thinking of different ways to solve them instead of giving up.",
                "I will suggest solutions when my friends or classmates face problems, whether it's in a game or a group project.",
                "I will practice solving puzzles, brain teasers, or math problems to improve my problem-solving skills.",
                "I will reflect on past problems I have solved and think about what I learned from those experiences."
            ],
            understand: "<h4>Being Good at Problem Solving and Coming Up with Solutions</h4> <p><strong>Description:</strong> This means being able to think of ways to overcome challenges. It's about being creative, thinking logically, and not getting upset when things are difficult.</p> <p><strong>Writing About Problem Solving and Solutions:</strong> Think of a time when you solved a problem or found a good solution. You can write about:</p> <ul> <li>A challenge you faced and how you thought of ways to solve it.</li> <li>How you helped someone else solve a problem.</li> <li>Activities you do that improve your problem-solving skills.</li> <li>A situation where you learned something valuable from solving a problem.</li> </ul>"
        },
        {
            id: 2345678901,
            index: 18,
            step: 4,
            icon: "rocket",
            name: "Good at Imagining the Future",
            improvements: [
                "I will think about what I want to be or do in the future and draw a picture or write a story about it.",
                "I will set a small goal for myself for the upcoming week or month and make a plan to achieve it.",
                "I will talk with family, friends, or teachers about different jobs and what people do in them to learn about future possibilities.",
                "I will imagine and describe how I think the world might change in the next ten years, considering technology, environment, and lifestyles."
            ],
            understand: "<h4>Being Good at Imagining the Future</h4> <p><strong>Description:</strong> Being good at imagining the future means thinking about what could happen or what you want to happen as you grow up. It's about setting goals, dreaming big, and being excited about possibilities.</p> <p><strong>Writing About Imagining the Future:</strong> Think of ways you have thought about the future. You can write about:</p> <ul> <li>Your ideas or dreams about what you want to be or do when you grow up.</li> <li>A goal you set for the near future and how you plan to achieve it.</li> <li>Conversations you've had about different careers or the future world.</li> <li>Your thoughts or predictions about how the world might change in the future.</li> </ul>"
        },
        {
            id: 3456789012,
            index: 19,
            step: 4,
            icon: "globe",
            name: "Responsible for Our Global Environment",
            improvements: [
                "I will learn about recycling and make sure to recycle things at school and at home.",
                "I will be mindful of not wasting water, like turning off the tap while brushing my teeth.",
                "I will participate in or organize a cleanup day in my school or community.",
                "I will learn about and share information on how to take care of our planet, like saving energy or protecting wildlife."
            ],
            understand: "<h4>Being Responsible for Our Global Environment</h4> <p><strong>Description:</strong> Being responsible for the environment means understanding how our actions affect the planet. It's about doing things to protect the earth, like recycling, saving water, and keeping our surroundings clean.</p> <p><strong>Writing About Environmental Responsibility:</strong> Think of ways you have helped take care of the planet. You can write about:</p> <ul> <li>Actions you take to recycle and why it's important.</li> <li>How you save water or energy in your daily life.</li> <li>A community or school cleanup activity you were part of.</li> <li>What you have learned about protecting the environment and how you share that knowledge with others.</li> </ul>"
        },
        {
            id: 4567890123,
            index: 20,
            step: 5,
            icon: "parachute-box",
            name: "Good at Taking Risks",
            improvements: [
                "I will try a new activity or join a club that interests me, even if I'm a little nervous about it.",
                "I will answer questions in class even when I'm not completely sure, to practice sharing my thoughts.",
                "I will make new friends by starting conversations with classmates I don't know well yet.",
                "I will experiment with creating something unique, like a piece of art or a story, without worrying about it being perfect."
            ],
            understand: "<h4>Being Good at Taking Risks</h4> <p><strong>Description:</strong> Being good at taking risks means trying new things even if they feel a bit scary or uncertain. It's about being brave, open to new experiences, and learning from them, whether they work out or not.</p> <p><strong>Writing About Taking Risks:</strong> Think of a time when you took a risk by trying something new or challenging. You can write about:</p> <ul> <li>A new activity or club you joined and what you learned from it.</li> <li>A time you spoke up in class even though you were unsure.</li> <li>How you made a new friend or talked to someone new.</li> <li>Creating something original and what the experience taught you.</li> </ul>"
        },
        {
            id: 5678901234,
            index: 21,
            step: 5,
            icon: "redo",
            name: "Good at Learning from Mistakes",
            improvements: [
                "I will reflect on what went wrong when something doesn't work out and think about how to do it better next time.",
                "I will ask for feedback or help if I make a mistake, to understand and improve.",
                "I will not be too hard on myself when I make a mistake, but use it as an opportunity to learn.",
                "I will share what I've learned from my mistakes with my classmates, to help them too."
            ],
            understand: "<h4>Being Good at Learning from Mistakes</h4> <p><strong>Description:</strong> Being good at learning from mistakes means understanding that mistakes are a part of learning. It's about thinking about what you can do differently next time and not giving up.</p> <p><strong>Writing About Learning from Mistakes:</strong> Think of a time when you made a mistake and what you learned from it. You can write about:</p> <ul> <li>A situation where something didn't go as planned and how you handled it.</li> <li>How you asked for help or advice after making a mistake.</li> <li>The way you moved past a mistake and what it taught you.</li> <li>Lessons you've shared with others about mistakes you've made.</li> </ul>"
        },
        {
            id: 6789012345,
            index: 22,
            step: 5,
            icon: "user-tie",
            name: "Good at Leading",
            improvements: [
                "I will take initiative in group projects or activities by suggesting ideas and helping organize tasks.",
                "I will show a positive attitude and encourage my classmates to work together and do their best.",
                "I will set a good example in my behavior, like following rules and being kind to others.",
                "I will listen to the ideas and opinions of others in the group, making sure everyone feels heard and valued."
            ],
            understand: "<h4>Being Good at Leading</h4> <p><strong>Description:</strong> Being good at leading means guiding and motivating others. It's about taking responsibility, being a positive role model, and helping your team to succeed.</p> <p><strong>Writing About Being Good at Leading:</strong> Think of a time when you were a leader. You can write about:</p> <ul> <li>How you took charge in a group task and what you did.</li> <li>Ways you encouraged and motivated your classmates or friends.</li> <li>How you set a good example for others.</li> <li>When you listened to and included everyone's ideas in a group decision.</li> </ul>"
        },
        {
            id: 7890123456,
            index: 23,
            step: 5,
            icon: "hammer",
            name: "Hardworking",
            improvements: [
                "I will complete all my school assignments on time and to the best of my ability, even if they are challenging.",
                "I will set aside regular time for studying and revision, and stick to this schedule.",
                "I will volunteer for additional responsibilities, like helping in the library or assisting with school events.",
                "I will persist with a difficult task or subject until I understand it, asking for help if I need it."
            ],
            understand: "<h4>Being Hardworking</h4> <p><strong>Description:</strong> Being hardworking means putting effort and energy into everything you do. It's about being dedicated, not giving up, and doing your best in all tasks, big or small.</p> <p><strong>Writing About Being Hardworking:</strong> Think of a time when you worked really hard at something. You can write about:</p> <ul> <li>An assignment or project you completed with lots of effort and dedication.</li> <li>Your regular study or practice routine and how it helps you.</li> <li>Extra responsibilities you've taken on and what you've learned from them.</li> <li>How you overcame a challenge by not giving up and continuing to try.</li> </ul>"
        },
        {
            id: 8901234567,
            index: 24,
            step: 5,
            icon: "stairs",
            name: "Ready for My Next Step",
            improvements: [
                "I will talk to teachers, family, or older students about what to expect in secondary school and how to prepare for it.",
                "I will work on organizing my time and belongings, skills that will be important in secondary school.",
                "I will participate in activities or clubs that will be available in secondary school to get a feel for them.",
                "I will think about my strengths and interests and how they might guide my choices in secondary school."
            ],
            understand: "<h4>Being Ready for Your Next Step</h4> <p><strong>Description:</strong> Being ready for your next step means preparing for future changes, like moving to secondary school. It's about gathering information, developing new skills, and thinking about your future.</p> <p><strong>Writing About Being Ready for Your Next Step:</strong> Think of ways you are getting ready for secondary school or another big change. You can write about:</p> <ul> <li>Conversations you've had about what to expect in the next stage of your education or life.</li> <li>Skills you are working on to help you succeed in the future.</li> <li>Activities you're trying now that will be part of your next step.</li> <li>How you're using your strengths and interests to plan for the future.</li> </ul>"
        }
        ]
    },
    {
        name: "I Feel",
        stage: "primary",
        imageUrl: "https://www.worldclass-schools.org/wp-content/uploads/2020/12/WCS06-I-Feel-transparent-480x480.png",
        path: "iFeel"
        , motto: "I feel..."
        , characteristics: [{
            id: 9012345678,
            index: 0,
            step: 0,
            icon: "smile-beam",
            name: "Positive",
            improvements: [
                "I will think of at least one good thing that happened each day and share it with a friend or family member.",
                "I will create a 'positivity jar' where I can write down positive moments and read them when I need a boost.",
                "I will try to find a positive side in challenging situations, like learning something new from a mistake.",
                "I will surround myself with positive messages, like motivational quotes or upbeat music."
            ],
            understand: "<h4>Feeling Positive</h4> <p><strong>Description:</strong> Feeling positive means looking at the bright side and focusing on the good things in life. It's about being hopeful and finding happiness in small things.</p> <p><strong>Writing About Feeling Positive:</strong> Think of times when you felt positive. You can write about:</p> <ul> <li>A good thing that happened and how it made you feel.</li> <li>How you kept a positive attitude during a tough time.</li> <li>A positive moment you added to your 'positivity jar.'</li> <li>Messages, songs, or activities that lift your mood and give you positive energy.</li> </ul>"
        },
        {
            id: 3456789,
            index: 26,
            step: 1,
            icon: "thumbs-up",
            name: "Good About Myself",
            improvements: [
                "I will set personal goals, like learning a new skill or improving in a subject, and celebrate when I achieve them.",
                "I will keep a list of my strengths and accomplishments and review it when I need a confidence boost.",
                "I will try new activities that interest me, recognizing that trying and learning is an achievement in itself.",
                "I will practice positive self-talk, reminding myself of my abilities and worth, especially when faced with challenges."
            ],
            understand: "<h4>Feeling Good About Myself</h4> <p><strong>Description:</strong> Feeling good about yourself means recognizing and appreciating your own value, strengths, and achievements. It's about having confidence in who you are and what you can do.</p> <p><strong>Writing About Feeling Good About Yourself:</strong> Think of times when you felt proud of yourself. You can write about:</p> <ul> <li>A personal goal you set and achieved, and how it made you feel.</li> <li>Your strengths or accomplishments that you are proud of.</li> <li>A new activity you tried and what you learned from it.</li> <li>Times when positive self-talk helped you overcome a difficult situation.</li> </ul>"
        },
        {
            id: 1234567890,
            index: 27,
            step: 1,
            icon: "rocket",
            name: "Excited",
            improvements: [
                "I will think about and plan for upcoming events or activities that I am looking forward to, like a school trip or a holiday.",
                "I will talk about what I'm excited about with my friends or family, sharing my enthusiasm and plans.",
                "I will explore new interests or hobbies that make me feel excited and energized.",
                "I will keep a diary or make drawings of things I am looking forward to or experiences that excite me."
            ],
            understand: "<h4>Feeling Excited</h4> <p><strong>Description:</strong> Feeling excited means looking forward to something with enthusiasm and eagerness. It's about feeling a sense of anticipation and joy about future events or experiences.</p> <p><strong>Writing About Feeling Excited:</strong> Think of times when you felt really excited. You can write about:</p> <ul> <li>An upcoming event or activity you were looking forward to and why it excited you.</li> <li>How sharing your excitement with others made you feel.</li> <li>New interests or hobbies that bring you excitement.</li> <li>Memories or plans you recorded in your diary that made you feel excited.</li> </ul>"
        },
        {
            id: 2345678901,
            index: 28,
            step: 2,
            icon: "snowflake",
            name: "Unique",
            improvements: [
                "I will create a piece of art, write a story, or engage in an activity that reflects my personal style and interests.",
                "I will celebrate the things that make me different, like my hobbies, talents, or the way I think.",
                "I will learn more about my cultural background or family history to understand and appreciate what makes me unique.",
                "I will share something special about myself with my classmates, like a unique skill, interest, or experience."
            ],
            understand: "<h4>Feeling Unique</h4> <p><strong>Description:</strong> Feeling unique means recognizing and valuing the things that make you different from others. It's about embracing your individuality and being proud of who you are.</p> <p><strong>Writing About Feeling Unique:</strong> Think of times when you felt special or different in a good way. You can write about:</p> <ul> <li>A personal project or activity that shows your unique style or interests.</li> <li>Aspects of yourself that you feel make you different and special.</li> <li>What you've learned about your background or family that contributes to your uniqueness.</li> <li>A unique skill, interest, or experience you shared with others.</li> </ul>"
        },
        {
            id: 3456789012,
            index: 29,
            step: 2,
            icon: "grin-stars",
            name: "Enthusiastic",
            improvements: [
                "I will actively participate in class discussions or activities that interest me, showing my enthusiasm.",
                "I will talk about topics or activities I am passionate about with friends and family.",
                "I will try new things with an open mind, looking for aspects that I might find exciting and engaging.",
                "I will set personal challenges in areas I enjoy, like reading a difficult book or mastering a new skill."
            ],
            understand: "<h4>Feeling Enthusiastic</h4> <p><strong>Description:</strong> Feeling enthusiastic means having a lot of excitement and interest in what you are doing or learning. It's about being eager and energetic in your actions and thoughts.</p> <p><strong>Writing About Feeling Enthusiastic:</strong> Think of times when you felt really enthusiastic. You can write about:</p> <ul> <li>Class discussions or activities that you were excited to participate in.</li> <li>Topics or hobbies that you love talking about and why they excite you.</li> <li>A new experience that you approached with enthusiasm.</li> <li>A challenge you set for yourself in an area you're passionate about and how you felt tackling it.</li> </ul>"
        },
        {
            id: 4567890123,
            index: 30,
            step: 3,
            icon: "users",
            name: "Part of a Team",
            improvements: [
                "I will contribute my ideas and listen to others in group projects or team activities.",
                "I will offer help to teammates who need it and ask for help when I need it.",
                "I will celebrate the successes of the team as a whole, rather than focusing only on my own contributions.",
                "I will engage in team-building activities, whether in school or outside, to strengthen our sense of unity and cooperation."
            ],
            understand: "<h4>Feeling Part of a Team</h4> <p><strong>Description:</strong> Feeling part of a team means feeling that you belong to a group and that you are important in it. It's about working together, supporting each other, and sharing successes and challenges.</p> <p><strong>Writing About Feeling Part of a Team:</strong> Think of times when you felt like you were an important member of a team. You can write about:</p> <ul> <li>Group projects where you worked well with others and what you achieved together.</li> <li>How you helped a teammate or received help yourself.</li> <li>A team success story and how you felt contributing to it.</li> <li>Team-building activities you participated in and what you learned from them.</li> </ul>"
        },
        {
            id: 5678901234,
            index: 31,
            step: 3,
            icon: "award",
            name: "Proud",
            improvements: [
                "I will set personal goals and work towards them, acknowledging my progress along the way.",
                "I will share my achievements with my family, friends, or teachers, whether it's a good grade, a new skill, or a kind act.",
                "I will reflect on challenges I've overcome and recognize the effort and perseverance it took.",
                "I will support and celebrate the achievements of others, recognizing that their successes can inspire my own."
            ],
            understand: "<h4>Feeling Proud</h4> <p><strong>Description:</strong> Feeling proud means feeling happy about something you or someone else has done. It's about recognizing achievements, effort, and progress, both in yourself and in others.</p> <p><strong>Writing About Feeling Proud:</strong> Think of times when you felt proud of yourself or someone else. You can write about:</p> <ul> <li>Personal goals you achieved and how they made you feel.</li> <li>Achievements you shared with others and their reactions.</li> <li>Difficulties you faced and overcame.</li> <li>Times when you felt proud of someone else's accomplishments and how it inspired you.</li> </ul>"
        },
        {
            id: 6789012345,
            index: 32,
            step: 4,
            icon: "star",
            name: "Talented",
            improvements: [
                "I will practice and hone a skill or talent I have, like playing an instrument, drawing, or playing a sport.",
                "I will share my talents with others, such as performing, displaying my work, or teaching a peer something I'm good at.",
                "I will seek feedback and ways to improve in areas where I feel talented, to continue growing and learning.",
                "I will explore new areas and activities that might reveal other talents or interests I have."
            ],
            understand: "<h4>Feeling Talented</h4> <p><strong>Description:</strong> Feeling talented means recognizing your own abilities and skills in certain areas. It's about being confident in what you do well and being open to improving and sharing your talents.</p> <p><strong>Writing About Feeling Talented:</strong> Think of times when you felt skilled or talented in something. You can write about:</p> <ul> <li>How you practice and develop a talent you have.</li> <li>Occasions when you shared your talent with others and their reactions.</li> <li>Feedback you received on your talent and how it helped you improve.</li> <li>New activities you tried that showed you have other talents or skills.</li> </ul>"
        },
        {
            id: 7890123456,
            index: 33,
            step: 5,
            icon: "book-reader",
            name: "A Love for Learning",
            improvements: [
                "I will explore topics that interest me by reading books, watching educational videos, or doing research.",
                "I will ask questions in class to deepen my understanding and satisfy my curiosity.",
                "I will engage in learning activities outside of school, like visiting museums, libraries, or participating in workshops.",
                "I will share what I learn with others, like giving a small presentation to my class or family."
            ],
            understand: "<h4>Feeling A Love for Learning</h4> <p><strong>Description:</strong> A love for learning means being excited about discovering and understanding new things. It's about being curious, asking questions, and enjoying the process of learning.</p> <p><strong>Writing About A Love for Learning:</strong> Think of times when you were really engaged and excited about learning something new. You can write about:</p> <ul> <li>Subjects or topics that fascinate you and how you explore them.</li> <li>Questions you asked in class that led to interesting discoveries.</li> <li>Educational activities or trips you participated in and what you learned from them.</li> <li>How you shared your knowledge or discoveries with others and their reactions.</li> </ul>"
        },
        {
            id: 8901234567,
            index: 34,
            step: 5,
            icon: "user-astronaut",
            name: "Confident",
            improvements: [
                "I will speak up in class or in group settings, sharing my ideas and opinions even when I feel a bit nervous.",
                "I will try new things, understanding that being confident means being okay with the possibility of making mistakes.",
                "I will prepare for tasks or activities, like studying for a test or practicing for a performance, to build my confidence.",
                "I will give myself positive affirmations, reminding myself of my strengths and abilities."
            ],
            understand: "<h4>Feeling Confident</h4> <p><strong>Description:</strong> Feeling confident means feeling sure about your abilities and about yourself. It's about being brave enough to express your thoughts and take on new challenges.</p> <p><strong>Writing About Feeling Confident:</strong> Think of times when you felt confident. You can write about:</p> <ul> <li>Instances where you spoke up or shared your ideas and how it felt.</li> <li>New things you tried and what you learned about yourself.</li> <li>How preparing for something made you feel more confident.</li> <li>Positive affirmations or thoughts that help boost your confidence.</li> </ul>"
        }
        ]
    },
    {
        name: "I Learn",
        stage: "primary",
        imageUrl: "https://www.worldclass-schools.org/wp-content/uploads/2020/12/WCS06-I-Learn-transparent-480x480.png.webp",
        path: "iLearn"
        , motto: "I learn by"
        , characteristics: [
            {
                id: 9012345678,
                index: 35,
                step: 0,
                icon: "balloons",
                name: "Celebrating",
                improvements: [
                    "I will recognize and celebrate my own achievements, no matter how small, like completing a difficult assignment or learning a new skill.",
                    "I will participate in class or school celebrations that acknowledge our collective achievements and learning.",
                    "I will create a 'celebration journal' where I note down my successes and positive learning experiences.",
                    "I will encourage and celebrate the achievements of my classmates, understanding that their successes also contribute to a positive learning environment."
                ],
                understand: "<h4>Learning by Celebrating</h4> <p><strong>Description:</strong> Learning by celebrating means acknowledging and taking joy in achievements, both your own and others'. It's about using celebration as a way to reinforce learning and build confidence.</p> <p><strong>Reflecting on Learning by Celebrating:</strong> Think of times when celebrating helped you in your learning. You can write about:</p> <ul> <li>Personal achievements you celebrated and how that made you feel about your learning.</li> <li>Class or school celebrations you were part of and what you learned from them.</li> <li>Entries in your 'celebration journal' that highlight your learning journey.</li> <li>Times you recognized and celebrated a classmate's success and how it fostered a positive learning experience for you.</li> </ul>"
            },
            {
                id: 123456789,
                index: 36,
                step: 0,
                icon: "user-check",
                name: "Behaving Appropriately",
                improvements: [
                    "I will follow classroom rules and show respect to teachers and classmates, understanding how this creates a better learning environment.",
                    "I will practice good manners, like raising my hand to speak and listening when others are talking.",
                    "I will work on controlling my emotions and reactions in class, using techniques like deep breathing or counting to ten when I feel upset.",
                    "I will resolve conflicts with classmates peacefully, using words to express how I feel and listening to their perspective."
                ],
                understand: "<h4>Learning by Behaving Appropriately</h4> <p><strong>Description:</strong> Learning by behaving appropriately means understanding that how you act affects not only your learning but also the learning of others. It's about being respectful, patient, and considerate in the classroom.</p> <p><strong>Reflecting on Learning by Behaving Appropriately:</strong> Think of times when your behavior positively contributed to your learning or the learning environment. You can write about:</p> <ul> <li>How following classroom rules helped you and others learn better.</li> <li>Instances where using good manners made classroom interactions more pleasant and effective.</li> <li>Techniques you used to manage your emotions in class and how it helped you focus.</li> <li>Ways you resolved conflicts with classmates that led to a better understanding or solution.</li> </ul>"
            },
            {
                id: 1234567890,
                index: 37,
                step: 1,
                icon: "person-running",
                name: "Being Active",
                improvements: [
                    "I will engage in school sports and physical education to enhance my focus and energy for learning.",
                    "I will do brief exercises or stretches to prepare my mind for studying or complex tasks.",
                    "I will participate in interactive classroom activities that involve movement, contributing to group work and discussions.",
                    "I will spend time outdoors, engaging in activities that stimulate both my physical and mental well-being."
                ],
                understand: "<h4>Learning by Being Active</h4> <p><strong>Description:</strong> Learning by being active involves using physical movement to support and improve your learning process. It's about engaging in activities that energize your body, which in turn helps your mind to focus and think clearly.</p> <p><strong>Reflecting on Learning by Being Active:</strong> Consider times when being physically active played a role in your learning. You can write about:</p> <ul> <li>Your experience in sports or physical activities at school and their impact on your learning.</li> <li>How incorporating movement into your study routine enhances your concentration and comprehension.</li> <li>Participation in dynamic classroom activities and how this interaction supports your learning.</li> <li>The benefits of outdoor activities for your overall learning and well-being.</li> </ul>"
            },
            {
                id: 2345678901,
                index: 38,
                step: 1,
                icon: "tree",
                name: "Working Outside",
                improvements: [
                    "I will take part in outdoor learning activities, like science experiments or nature walks, to explore different subjects.",
                    "I will engage in outdoor group projects, like gardening or environmental studies, to learn teamwork and practical skills.",
                    "I will observe and record changes in the environment, like weather patterns or plant growth, as part of my learning.",
                    "I will seek opportunities for creative projects outdoors, like art or writing, that are inspired by nature."
                ],
                understand: "<h4>Learning by Working Outside</h4> <p><strong>Description:</strong> Learning by working outside means using the outdoor environment to enhance your understanding of various subjects. It's about experiencing learning in a natural setting, which can offer new perspectives and insights.</p> <p><strong>Reflecting on Learning by Working Outside:</strong> Think about how outdoor activities have contributed to your learning. You can write about:</p> <ul> <li>Outdoor lessons and how they helped you see a subject in a new way.</li> <li>Team projects you did outside and the skills you gained from them.</li> <li>Observations you made about the environment and what you learned.</li> <li>Creative work inspired by being outdoors and how it influenced your thoughts or feelings about a topic.</li> </ul>"
            },
            {
                id: 3456789012,
                index: 39,
                step: 2,
                icon: "globe-americas",
                name: "Going to Places and Finding Them Exciting",
                improvements: [
                    "I will participate in school trips to museums, historical sites, or science centers, and actively engage in the learning opportunities they provide.",
                    "I will explore local places of interest, like libraries, parks, or community centers, to learn more about my surroundings.",
                    "I will keep a journal or create a project based on my experiences from these visits, highlighting what I found most exciting.",
                    "I will share my experiences with classmates or family, discussing what I learned and what made these places exciting."
                ],
                understand: "<h4>Learning by Going to Places and Finding Them Exciting</h4> <p><strong>Description:</strong> Learning by going to places involves exploring new environments and finding excitement in the discovery process. It's about gaining knowledge and inspiration from visiting different locations and observing the world around you.</p> <p><strong>Reflecting on Learning by Going to Places:</strong> Think about your visits to various places and how they enhanced your learning. You can write about:</p> <ul> <li>What you learned from a school trip or visit to a local place of interest.</li> <li>Your personal observations and experiences from these visits and why they were exciting.</li> <li>Projects or journals you created based on your experiences.</li> <li>Discussions with others about your visits and their educational value.</li> </ul>"
            },
            {
                id: 4567890123,
                index: 40,
                step: 2,
                icon: "school",
                name: "Coming to School Regularly",
                improvements: [
                    "I will organize my morning and evening routines to ensure I’m prepared for school each day.",
                    "I will keep track of my attendance and strive to maintain good attendance records.",
                    "I will communicate with my teachers or school if I face challenges that might affect my regular attendance."
                ],
                understand: "<h4>Learning by Coming to School Regularly</h4> <p><strong>Description:</strong> Learning by coming to school regularly means understanding the importance of being present in the classroom. Regular attendance helps you keep up with lessons, participate in group activities, and stay connected with the school community.</p> <p><strong>Reflecting on Learning by Coming to School Regularly:</strong> Think about how being present at school has helped your learning. Consider:</p> <ul> <li>What you learn each day at school and how missing a day can impact your understanding.</li> <li>How your daily routines help you prepare for school.</li> <li>The importance of good attendance and its role in your overall learning experience.</li> <li>Ways you've overcome challenges to maintain regular attendance.</li> </ul>"
            },
            {
                id: 5678901234,
                index: 41,
                step: 3,
                icon: "edit",
                name: "Improving My Work",
                improvements: [
                    "I will review my schoolwork regularly to identify areas for improvement and make necessary changes.",
                    "I will ask for feedback from teachers and peers on my assignments and projects, and use their suggestions to enhance my work.",
                    "I will set personal goals for improvement in different subjects and work steadily towards achieving them.",
                    "I will keep a portfolio of my work to track my progress over time and reflect on my learning journey."
                ],
                understand: "<h4>Learning by Improving My Work</h4> <p><strong>Description:</strong> Learning by improving your work means actively seeking ways to make your work better. It involves self-reflection, seeking feedback, and being open to making changes for continuous improvement.</p> <p><strong>Reflecting on Learning by Improving My Work:</strong> Consider how making improvements in your work has helped your learning. You can write about:</p> <ul> <li>How you reviewed and improved a piece of schoolwork.</li> <li>The feedback you received and how you used it to enhance your work.</li> <li>Personal goals you set for your academic improvement and your progress in achieving them.</li> <li>Your experience of tracking your learning and development through a work portfolio.</li> </ul>"
            },
            {
                id: 6789012345,
                index: 42,
                step: 3,
                icon: "paint-brush",
                name: "Having Hobbies",
                improvements: [
                    "I will dedicate time to pursuing my hobbies, whether it's art, music, sports, reading, or any other interest.",
                    "I will explore new hobbies or activities to broaden my skills and knowledge.",
                    "I will connect with others who share my hobbies to learn from them and share experiences.",
                    "I will reflect on what I learn from my hobbies and how these skills can apply to other areas of my life."
                ],
                understand: "<h4>Learning by Having Hobbies</h4> <p><strong>Description:</strong> Learning by having hobbies means gaining new skills and insights through activities you enjoy. Hobbies can teach you about creativity, problem-solving, and persistence, and can be a fun way to learn outside of the classroom.</p> <p><strong>Reflecting on Learning by Having Hobbies:</strong> Think about your hobbies and what you learn from them. You can write about:</p> <ul> <li>The hobbies you are passionate about and what skills they help you develop.</li> <li>New hobbies you've tried and what you've discovered through them.</li> <li>How your hobbies connect you with others and what you learn from these connections.</li> <li>Ways in which your hobbies enhance other aspects of your life and learning.</li> </ul>"
            },
            {
                id: 7890123456,
                index: 43,
                step: 3,
                icon: "book-open",
                name: "Reading Lots of Different Things",
                improvements: [
                    "I will explore a variety of reading materials, including books, magazines, newspapers, and online articles, on different subjects.",
                    "I will set aside time each day for reading, aiming to discover new topics and ideas.",
                    "I will participate in reading challenges or join a reading club to stay motivated and explore diverse genres.",
                    "I will discuss what I read with others, sharing insights and gaining different perspectives."
                ],
                understand: "<h4>Learning by Reading Lots of Different Things</h4> <p><strong>Description:</strong> Learning by reading a variety of materials expands your knowledge and opens your mind to new ideas. It involves exploring different subjects, stories, and perspectives, and thinking about what you read.</p> <p><strong>Reflecting on Learning by Reading:</strong> Think about how reading various things has contributed to your learning. You can write about:</p> <ul> <li>Different types of reading material you enjoy and what you learn from them.</li> <li>Your daily reading habits and how they help you discover new things.</li> <li>Your experience with reading challenges or clubs and the variety of genres you've explored.</li> <li>Discussions you've had about your reading and how they have broadened your understanding.</li> </ul>"
            },
            {
                id: 8901234567,
                index: 44,
                step: 4,
                icon: "users",
                name: "Meeting People from Other Places",
                improvements: [
                    "I will engage with students or visitors from different places, learning about their cultures and experiences.",
                    "I will participate in school exchange programs or cultural events that introduce me to new perspectives.",
                    "I will ask questions and show genuine interest when meeting people from other places, aiming to understand their viewpoints.",
                    "I will collaborate on projects or activities with students who come from different backgrounds, to learn from our shared experiences."
                ],
                understand: "<h4>Learning by Meeting People from Other Places</h4> <p><strong>Description:</strong> Learning by meeting people from other places means gaining knowledge and understanding from others' experiences and cultures. It's about being open to new ideas and building connections that broaden your perspective.</p> <p><strong>Reflecting on Learning by Meeting Others:</strong> Think about your experiences with people from different places and what you learned from them. You can write about:</p> <ul> <li>Interactions with people from different cultures and what insights you gained.</li> <li>Your involvement in exchange programs or cultural events and their impact on your learning.</li> <li>Questions you asked to learn more about other cultures and perspectives.</li> <li>Collaborative projects with diverse peers and the knowledge you gained from working together.</li> </ul>"
            },
            {
                id: 9012345678,
                index: 45,
                step: 4,
                icon: "home",
                name: "Looking After My School and Home",
                improvements: [
                    "I will take an active role in keeping my classroom tidy, like organizing materials and cleaning up after activities.",
                    "I will participate in school initiatives like recycling programs or gardening projects to contribute to a better school environment.",
                    "I will help with chores at home, understanding how this responsibility teaches me about organization and care.",
                    "I will take pride in my personal spaces, both at school and home, by keeping them clean and orderly."
                ],
                understand: "<h4>Learning by Looking After My School and Home</h4> <p><strong>Description:</strong> Learning by looking after your school and home means understanding the importance of taking care of your surroundings. It's about learning responsibility, organization, and respect for the places where you live and learn.</p> <p><strong>Reflecting on Learning by Caring for Your Environments:</strong> Think about how taking care of your school and home has helped you learn. You can write about:</p> <ul> <li>Ways you help keep your classroom and school tidy and the impact it has.</li> <li>School projects you participated in that improved the school environment.</li> <li>Home chores you do and what they teach you about responsibility.</li> <li>How maintaining order in your personal spaces makes you feel and contributes to your learning.</li> </ul>"
            },
            {
                id: 3456789,
                index: 46,
                step: 4,
                icon: "calendar-alt",
                name: "Being Organized",
                improvements: [
                    "I will keep my schoolwork and materials organized, using folders or notebooks to manage my assignments and notes.",
                    "I will create a weekly schedule to balance my school tasks, hobbies, and rest time effectively.",
                    "I will prepare for the next school day each evening, ensuring I have everything I need, like completed homework and school supplies.",
                    "I will set reminders for important dates and deadlines, like project due dates or upcoming tests."
                ],
                understand: "<h4>Learning by Being Organized</h4> <p><strong>Description:</strong> Learning by being organized involves managing your time, tasks, and materials efficiently. It's about creating order and structure, which helps you focus and achieve better results in your learning.</p> <p><strong>Reflecting on Learning by Being Organized:</strong> Think about how being organized has helped you in your learning. You can write about:</p> <ul> <li>How you keep your schoolwork and materials in order and its impact on your studies.</li> <li>Your weekly schedule and how it helps you manage your time for school and personal activities.</li> <li>Your evening routine in preparation for the next school day and its benefits.</li> <li>Using reminders for important dates and how it keeps you on track with your responsibilities.</li> </ul>"
            },
            {
                id: 1234567890,
                index: 47,
                step: 4,
                icon: "search",
                name: "Researching",
                improvements: [
                    "I will use the library and online resources to gather information for school projects and assignments.",
                    "I will develop questions about topics I'm interested in and seek out answers through research.",
                    "I will learn to evaluate sources for their reliability and relevance to ensure the quality of my research.",
                    "I will present my research findings to my class or family to share what I've learned and get feedback."
                ],
                understand: "<h4>Learning by Researching</h4> <p><strong>Description:</strong> Learning by researching means actively seeking out information to expand your knowledge. It's about being curious, asking questions, and using resources like books and the internet to find answers.</p> <p><strong>Reflecting on Learning by Researching:</strong> Think about how conducting research has contributed to your learning. You can write about:</p> <ul> <li>Research projects you've undertaken and what you discovered.</li> <li>Questions that sparked your research and how you sought answers.</li> <li>How you determine which sources are reliable and useful for your research.</li> <li>Experiences of sharing your research findings with others and their reactions.</li> </ul>"
            },
            {
                id: 2345678901,
                index: 48,
                step: 5,
                icon: "hands-helping",
                name: "Helping Other People Learn",
                improvements: [
                    "I will volunteer to help classmates who may be struggling with certain subjects or concepts.",
                    "I will engage in study groups where we can share knowledge and learn from each other.",
                    "I will take opportunities to explain or demonstrate concepts to others, which reinforces my own understanding.",
                    "I will listen attentively to others' questions and thoughts, offering insights or explanations when I can."
                ],
                understand: "<h4>Learning by Helping Other People Learn</h4> <p><strong>Description:</strong> Learning by helping others means understanding that teaching and assisting peers can deepen your own knowledge. It's about collaboration, communication, and reinforcing your learning through helping others.</p> <p><strong>Reflecting on Learning by Helping Others:</strong> Think about times when you helped someone else learn. You can write about:</p> <ul> <li>Instances where you assisted a classmate and how it also helped you understand the topic better.</li> <li>Your experiences in study groups and what you gained from them.</li> <li>Times when you explained a concept to someone and how that reinforced your own learning.</li> <li>How listening to and addressing others' questions or challenges enhanced your understanding.</li> </ul>"
            },
            {
                id: 3456789012,
                index: 49,
                step: 5,
                icon: "user-plus",
                name: "Being a Positive Role Model",
                improvements: [
                    "I will consistently demonstrate good behavior and a positive attitude in school and at home.",
                    "I will show respect, kindness, and cooperation in my interactions, setting a good example for my peers.",
                    "I will take responsibility for my actions and decisions, showing others how to be accountable.",
                    "I will strive for excellence in my schoolwork and activities, encouraging others to do their best as well."
                ],
                understand: "<h4>Learning by Being a Positive Role Model</h4> <p><strong>Description:</strong> Learning by being a positive role model means leading by example. It's about showing others how to behave and learn effectively through your own actions and attitudes.</p> <p><strong>Reflecting on Learning by Being a Role Model:</strong> Think about how your behavior and attitude influence others and your own learning. You can write about:</p> <ul> <li>Ways in which you demonstrate positive behavior and its impact on those around you.</li> <li>Instances where your respect and kindness influenced a situation positively.</li> <li>How taking responsibility has helped you and shown others how to do the same.</li> <li>Your efforts to excel in your work and how this motivates others.</li> </ul>"
            },
            {
                id: 4567890123,
                index: 50,
                step: 5,
                icon: "globe-europe",
                name: "Understanding How the World Works",
                improvements: [
                    "I will engage in lessons about geography, science, and cultures to gain a broader understanding of the world.",
                    "I will participate in discussions and projects that explore global issues and diverse perspectives.",
                    "I will seek out books, documentaries, and other resources that provide insight into different aspects of the world.",
                    "I will ask questions about how things work, from nature to technology, to deepen my understanding."
                ],
                understand: "<h4>Learning by Understanding How the World Works</h4> <p><strong>Description:</strong> Learning by understanding how the world works involves exploring different subjects that explain the natural, social, and technological aspects of our world. It's about being curious and seeking knowledge that helps you make sense of the world around you.</p> <p><strong>Reflecting on Understanding the World:</strong> Think about your exploration of the world and how it has contributed to your learning. You can write about:</p> <ul> <li>Subjects and lessons that helped you understand the world better.</li> <li>Discussions or projects on global issues and what insights you gained.</li> <li>Resources like books or films that provided you with a broader perspective.</li> <li>Questions you've asked to learn more about the world and the answers you found.</li> </ul>"
            },
            {
                id: 5678901234,
                index: 51,
                step: 5,
                icon: "calculator",
                name: "Understanding How Mathematics and Technology Work in the Real World",
                improvements: [
                    "I will explore practical applications of mathematics in daily life, like budgeting, cooking measurements, or time management.",
                    "I will engage in projects that use technology to solve real-world problems, such as coding a simple app or designing a website.",
                    "I will participate in math and technology clubs or competitions that focus on practical applications.",
                    "I will stay curious about how technology works and how it's developed, asking questions and seeking out information."
                ],
                understand: "<h4>Learning by Understanding Mathematics and Technology in the Real World</h4> <p><strong>Description:</strong> Learning by understanding how mathematics and technology apply in real life involves seeing the relevance of these subjects beyond the classroom. It's about recognizing the practical uses of math and technology in everyday situations and future advancements.</p> <p><strong>Reflecting on Practical Applications:</strong> Consider how you have used mathematics and technology in real-world contexts. You can write about:</p> <ul> <li>Everyday activities where you applied mathematical concepts.</li> <li>Technology projects you worked on and what real-world problems they addressed.</li> <li>Your involvement in extracurricular activities that focus on practical math and technology applications.</li> <li>Questions and discoveries about the way technology is used and developed in the modern world.</li> </ul>"
            },
            {
                id: 6789012345,
                index: 52,
                step: 5,
                icon: "briefcase",
                name: "Understanding About Business and the World of Work",
                improvements: [
                    "I will engage in classroom discussions or projects related to business, economics, and different professions.",
                    "I will participate in school activities like 'career day' or 'enterprise projects' that simulate real-world business experiences.",
                    "I will interview family members or community figures about their work to gain insights into different careers.",
                    "I will explore basic concepts of money management, entrepreneurship, and the value of different jobs in society."
                ],
                understand: "<h4>Learning by Understanding About Business and the World of Work</h4> <p><strong>Description:</strong> Learning about business and the world of work means exploring how businesses operate and what it's like to have a job. It's about understanding the role of work in society and the skills needed in different careers.</p> <p><strong>Reflecting on Business and Work:</strong> Think about what you have learned about the business world and different jobs. You can write about:</p> <ul> <li>Your classroom experiences learning about business and careers.</li> <li>School activities that gave you a taste of real-world business and work.</li> <li>What you learned from talking to people about their jobs.</li> <li>Your understanding of money management and the basics of entrepreneurship.</li> </ul>"
            }
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            

        ]

    }

]

export default areasConfig
