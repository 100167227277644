import React, { useState, useRef } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import app from "../../firebase";
import { reportConfig } from "./reportConfig";
import { convertTableToBarChart } from "./tableToChart";


const StaticReport = ({ data, schoolData }) => {
    const [reportData, setReportData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [progress, setProgress] = useState(0); // Progress for the progress bar
    const [threadId, setThreadId] = useState(null);
    const reportRef = useRef(); // Reference for the report container

    const computeCalculations = (calculations, data) => {
        return calculations.map(({ label, logic }) => {
            try {
                const func = eval(logic);
                const result = func(data);
                return { label, result };
            } catch (error) {
                console.error(`Error executing calculation "${label}":`, error);
                return { label, result: "Error in calculation" };
            }
        });
    };

    const generateMultilinePrompt = (section, computedResults) => {
        const resultsString = computedResults
            .map(({ label, result }) => `${label}: ${JSON.stringify(result, null, 2)}
            
            `)
            .join("\n\n");

        return `
Title: ${section.title}
## IMPORTANT INSTRUCTIONS
${section.prompt}

#### BeWorldClass data
${resultsString}
        `.trim();
    };

    const handleGenerateReport = async () => {
        setIsLoading(true);
        setError(null);
        setProgress(0);

        const functions = getFunctions(app);
        const staticReport = httpsCallable(functions, "staticReport");

        try {
            let currentThreadId = threadId; // Use a local variable to track threadId updates synchronously

            for (const [index, section] of reportConfig.sections.entries()) {
                const computedResults = computeCalculations(section.calculations, data);
                const multilinePrompt = generateMultilinePrompt(section, computedResults);

                const requestData = {
                    schoolId: schoolData.path,
                    userMessage: multilinePrompt,
                    conversationHistory: JSON.stringify([], null, 2),
                    threadId: currentThreadId, // Use the local variable here
                };

                const response = await staticReport(requestData);
                const { message, threadId: newThreadId } = response.data;

                currentThreadId = newThreadId; // Update the local variable synchronously
                setThreadId(newThreadId); // Update React state for the latest threadId

                const convertedMessage = convertTableToBarChart(message || "<p>No response message from the cloud function.</p>");

                setReportData((prevReportData) => [
                    ...prevReportData,
                    {
                        title: section.title,
                        message: convertedMessage || "<p>No response message from the cloud function.</p>",
                    },
                ]);

                setProgress(((index + 1) / reportConfig.sections.length) * 100); // Update progress
            }
        } catch (err) {
            console.error("Error generating report:", err);
            setError("Failed to generate the report. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const handlePrint = () => {
        window.print();
    };

    const currentDate = new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });

    return (
        <div className="data-report p-4" ref={reportRef}>
            <div className="no-print">

                {!isLoading && !reportData.length && (
                    <div style={{ marginTop: "7rem" }} className="text-center">
                        <button
                            onClick={handleGenerateReport}
                            className="btn btn-primary btn-lg mb-4"
                        >
                            Generate Report
                        </button>

                    </div>
                )}

                {isLoading && (
                    <div className="text-center">
                        <div className="d-flex align-items-center justify-content-center mb-3">
                            <h4 className="mb-0 me-3">Generating report</h4>
                            <div className="spinner-border text-primary" role="status" aria-hidden="true"></div>
                        </div>
                        <div className="progress" style={{ height: "30px" }}>
                            <div
                                className="progress-bar progress-bar-striped progress-bar-animated"
                                role="progressbar"
                                style={{
                                    width: `${progress}%`,
                                }}
                                aria-valuenow={progress}
                                aria-valuemin="0"
                                aria-valuemax="100"
                            >
                                {Math.round(progress)}%
                            </div>
                        </div>
                        <p className="text-danger">Please do not close the window until you have saved the report</p>
                    </div>
                )}

                {error && <div className="alert alert-danger mt-4">{error}</div>}

                {progress > 99 &&


                    < div className="text-center mt-4">
                        <button
                            onClick={handlePrint}
                            className="btn btn-success btn-lg"
                        >
                            Save/Print Report
                        </button>
                    </div>
                }

                {reportData.length > 0 && (
                    <hr style={{ marginTop: "7rem", marginBottom: "7rem" }} />
                )}


            </div>
            {
                reportData.length > 0 && (
                    <>
                        {/* Logo */}
                        <div
                            className="d-flex flex-column align-items-center justify-content-center text-center"
                        >
                            <img
                                src="https://i0.wp.com/beworldclass.org/wp-content/uploads/2022/06/cropped-BeWorldClass-transparent-rgb.png"
                                alt="BeWorldClass Logo"
                                style={{ width: "400px" }}
                            />
                        </div>

                        {/* Cover Page */}
                        <div
                            className="d-flex flex-column align-items-center justify-content-center text-center vh-100"
                        >
                            <h1 className="display-3 text-primary mb-3">{schoolData.name}</h1>
                            <h2 className="fs-4 fst-italic text-muted mb-5">
                                Whole-School Character and Career Education Report
                            </h2>
                            <p className="fs-5 text-primary fst-italic">{currentDate}</p>
                        </div>



                        {reportData.map((section, index) => (
                            <div key={index} className="report-container-pdf mt-5">
                                <h1>{section.title}</h1>
                                <div dangerouslySetInnerHTML={{ __html: section.message }} />
                            </div>
                        ))}
                    </>
                )
            }


        </div >
    );
};

export default StaticReport;
