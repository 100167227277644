// Sample reportConfig (can be loaded from a file or API if needed)
export const reportConfig = {
    "sections": [
        {
            "title": "Introduction",
            "Prompt": `1. Start with an introduction to the study, this focuses mostly on assessing the data sample, explaining the AI data from BeWorldClass that will be explored in the future, a brief number of key statistics mentioned. 
            2.BeWorldClass is designed to collect data across the whole school so an expected sample is usually in the hundreds of students across all year groups, make a statement of how good the data set is and what kind of conclusions can be drawn from this sample.
            3. An explanation of the time saved through AI analysis, a study in this depth without AI may not be possible across a whole school.  
            
            
            Now is not the time to draw conclusions on character development, it will be addressed in following sections`,
            "calculations": [
                {
                    "label": "Total Sample Size",
                    "logic": "(data) => data.length"
                },
                {
                    "label": "Number of Students",
                    "logic": "(data) => { const numStudents = new Set(data.map(item => item.uid)).size; let evaluation = 'Poor'; if (numStudents > 700) evaluation = 'Outstanding'; else if (numStudents > 300) evaluation = 'Good'; else if (numStudents > 100) evaluation = 'Satisfactory'; else if (numStudents > 50) evaluation = 'Some Suitability'; return { 'Number of Students': numStudents, 'Sample Size Evaluation': evaluation }; }"
                },
                {
                    "label": "Entries by Year Group for bar-chart",
                    "logic": "(data) => data.reduce((acc, item) => { const year = `Year ${item.yearGroup}`; acc[year] = (acc[year] || 0) + 1; return acc; }, {})"
                },
                {
                    "label": "Time saved with AI compared with a manual analysis. DON'T USE A TABLE HERE",
                    "logic": "(data) => ({ 'Reflection Feedback': data.length * 3, 'Categorization': data.length * 7, 'Analysis & Report Writing': 720, 'Total Saved Time': (data.length * 3) + (data.length * 7) + 720 })"
                }
            ]            
        },
        {
            "title": "Character Areas Overview",
            "prompt": "Give a detailed section of the areas of character. There will be a lot of information. Take a particular focus on pointing out the most notable statistics",
            "calculations": [
                {
                    "label": "All Areas Present with Counts",
                    "logic": "(data) => data.reduce((acc, item) => { acc[item.areaName] = (acc[item.areaName] || 0) + 1; return acc; }, {})"
                },
                {
                    "label": "Area Development in School vs Other Locations",
                    "logic": "(data) => data.reduce((acc, item) => { const location = item.location || 'Unspecified'; acc[item.areaName] = acc[item.areaName] || { 'At School': 0, 'At Home': 0, 'Other': 0, 'At a Place of Worship': 0 }; if (location === 'at school') acc[item.areaName]['At School']++; if (location === 'at home') acc[item.areaName]['At Home']++; if (location === 'other') acc[item.areaName]['Other']++; if (location === 'at a place of worship') acc[item.areaName]['At a Place of Worship']++; return acc; }, {})"
                },
                {
                    "label": "Areas by Year Group",
                    "logic": "(data) => data.reduce((acc, item) => { const year = `Year ${item.yearGroup}`; acc[item.areaName] = acc[item.areaName] || {}; acc[item.areaName][year] = (acc[item.areaName][year] || 0) + 1; return acc; }, {})"
                },
                {
                    "label": "Strongest/Weakest Areas by Confidence",
                    "logic": "(data) => { const confidenceData = data.reduce((acc, item) => { acc[item.areaName] = acc[item.areaName] || { total: 0, count: 0 }; acc[item.areaName].total += (item.confidence || 0); acc[item.areaName].count++; return acc; }, {}); return Object.keys(confidenceData).reduce((result, key) => { const avg = confidenceData[key].total / confidenceData[key].count; result[key] = { 'Average Confidence': avg.toFixed(2),  'Entries': confidenceData[key].count }; return result; }, {}); }"
                },
                {
                    "label": "Strongest/Weakest Areas by Understanding",
                    "logic": "(data) => { const understandingData = data.reduce((acc, item) => { acc[item.areaName] = acc[item.areaName] || { total: 0, count: 0 }; acc[item.areaName].total += (item.understanding || 0); acc[item.areaName].count++; return acc; }, {}); return Object.keys(understandingData).reduce((result, key) => { const avg = understandingData[key].total / understandingData[key].count; result[key] = { 'Average Understanding': avg.toFixed(2),  'Entries': understandingData[key].count }; return result; }, {}); }"
                },
                {
                    "label": "Roles Taken by Area",
                    "logic": "(data) => data.reduce((acc, item) => { acc[item.areaName] = acc[item.areaName] || {}; acc[item.areaName][item.role] = (acc[item.areaName][item.role] || 0) + 1; return acc; }, {})"
                },
                {
                    "label": "Actions Benefiting Other Groups by Area",
                    "logic": "(data) => data.reduce((acc, item) => { if (item.affects === 'others') acc[item.areaName] = (acc[item.areaName] || 0) + 1; return acc; }, {})"
                }
            ]
        },
        
        {
            "title": "Subjects Analysis",
            "Prompt": "BeWorldClass used AI to categorise reflections to see what subjects are being mentioned. Write a detailed section on the data, with particular focus on entries that do not conform to specific subject bounds. ",
            "calculations": [
                {
                    "label": "Frequency of Subjects",
                    "logic": "(data) => data.reduce((acc, item) => { if (item.subject) { acc[item.subject] = (acc[item.subject] || 0) + 1; } return acc; }, {})"
                },
                {
                    "label": "Top 3 Subjects for In-Depth Analysis",
                    "logic": "(data) => { const subjects = data.reduce((acc, item) => { if (item.subject) { acc[item.subject] = (acc[item.subject] || 0) + 1; } return acc; }, {}); return Object.keys(subjects).sort((a, b) => subjects[b] - subjects[a]).slice(0, 3); }"
                },
                {
                    "label": "Character Areas Developed by Subject (Top 3)",
                    "logic": "(data) => { const top3 = data.reduce((acc, item) => { if (item.subject) { acc[item.subject] = (acc[item.subject] || 0) + 1; } return acc; }, {}); const top3Keys = Object.keys(top3).sort((a, b) => top3[b] - top3[a]).slice(0, 3); return data.filter(item => top3Keys.includes(item.subject)).reduce((acc, item) => { acc[item.subject] = acc[item.subject] || {}; acc[item.subject][item.areaName] = (acc[item.subject][item.areaName] || 0) + 1; return acc; }, {}); }"
                },
                {
                    "label": "Subjects by Location (Top 3)",
                    "logic": "(data) => { const top3 = data.reduce((acc, item) => { if (item.subject) { acc[item.subject] = (acc[item.subject] || 0) + 1; } return acc; }, {}); const top3Keys = Object.keys(top3).sort((a, b) => top3[b] - top3[a]).slice(0, 3); return data.filter(item => top3Keys.includes(item.subject)).reduce((acc, item) => { acc[item.subject] = acc[item.subject] || { 'At School': 0, 'At Home': 0, 'Other': 0, 'At a Place of Worship': 0 }; const location = item.location || 'Unspecified'; if (location === 'at school') acc[item.subject]['At School']++; if (location === 'at home') acc[item.subject]['At Home']++; if (location === 'other') acc[item.subject]['Other']++; if (location === 'at a place of worship') acc[item.subject]['At a Place of Worship']++; return acc; }, {}); }"
                },
                {
                    "label": "Subjects by Year Group (Top 3)",
                    "logic": "(data) => { const top3 = data.reduce((acc, item) => { if (item.subject) { acc[item.subject] = (acc[item.subject] || 0) + 1; } return acc; }, {}); const top3Keys = Object.keys(top3).sort((a, b) => top3[b] - top3[a]).slice(0, 3); return data.filter(item => top3Keys.includes(item.subject)).reduce((acc, item) => { acc[item.subject] = acc[item.subject] || {}; const year = `Year ${item.yearGroup}`; acc[item.subject][year] = (acc[item.subject][year] || 0) + 1; return acc; }, {}); }"
                },
                {
                    "label": "Strongest/Weakest Subjects by Confidence (Top 3)",
                    "logic": "(data) => { const top3 = data.reduce((acc, item) => { if (item.subject) { acc[item.subject] = (acc[item.subject] || 0) + 1; } return acc; }, {}); const top3Keys = Object.keys(top3).sort((a, b) => top3[b] - top3[a]).slice(0, 3); const confidenceData = data.filter(item => top3Keys.includes(item.subject)).reduce((acc, item) => { acc[item.subject] = acc[item.subject] || { total: 0, count: 0 }; acc[item.subject].total += (item.confidence || 0); acc[item.subject].count++; return acc; }, {}); return Object.keys(confidenceData).reduce((result, key) => { const avg = confidenceData[key].total / confidenceData[key].count; result[key] = { 'Average Confidence': avg.toFixed(2),  'Entries': confidenceData[key].count }; return result; }, {}); }"
                },
                {
                    "label": "Strongest/Weakest Subjects by Understanding (Top 3)",
                    "logic": "(data) => { const top3 = data.reduce((acc, item) => { if (item.subject) { acc[item.subject] = (acc[item.subject] || 0) + 1; } return acc; }, {}); const top3Keys = Object.keys(top3).sort((a, b) => top3[b] - top3[a]).slice(0, 3); const understandingData = data.filter(item => top3Keys.includes(item.subject)).reduce((acc, item) => { acc[item.subject] = acc[item.subject] || { total: 0, count: 0 }; acc[item.subject].total += (item.understanding || 0); acc[item.subject].count++; return acc; }, {}); return Object.keys(understandingData).reduce((result, key) => { const avg = understandingData[key].total / understandingData[key].count; result[key] = { 'Average Understanding': avg.toFixed(2), 'Entries': understandingData[key].count }; return result; }, {}); }"
                },
                {
                    "label": "Roles Taken by Subject (Top 3)",
                    "logic": "(data) => { const top3 = data.reduce((acc, item) => { if (item.subject) { acc[item.subject] = (acc[item.subject] || 0) + 1; } return acc; }, {}); const top3Keys = Object.keys(top3).sort((a, b) => top3[b] - top3[a]).slice(0, 3); return data.filter(item => top3Keys.includes(item.subject)).reduce((acc, item) => { acc[item.subject] = acc[item.subject] || {}; acc[item.subject][item.role] = (acc[item.subject][item.role] || 0) + 1; return acc; }, {}); }"
                },
                {
                    "label": "Actions Benefiting Others by Subject (Top 3)",
                    "logic": "(data) => { const top3 = data.reduce((acc, item) => { if (item.subject) { acc[item.subject] = (acc[item.subject] || 0) + 1; } return acc; }, {}); const top3Keys = Object.keys(top3).sort((a, b) => top3[b] - top3[a]).slice(0, 3); return data.filter(item => top3Keys.includes(item.subject)).reduce((acc, item) => { if (item.affects === 'others') acc[item.subject] = (acc[item.subject] || 0) + 1; return acc; }, {}); }"
                },
                {
                    "label": "No Subject Context from Other Data",
                    "logic": "(data) => data.filter(item => !item.subject).reduce((acc, item) => { const key = item.other || 'None'; acc[key] = acc[key] || { confidenceTotal: 0, confidenceCount: 0, understandingTotal: 0, understandingCount: 0 }; acc[key].confidenceTotal += (item.confidence || 0); acc[key].confidenceCount++; acc[key].understandingTotal += (item.understanding || 0); acc[key].understandingCount++; return acc; }, {})"
                }
            ]
        },

        {
            "title": "Initiatives Analysis",
            "prompt": "Introduce the section by explaining tags were added with AI, these tags track things like clubs and specific actions or initiatives that students are involved in. If initative is categorised as none it would usually be a subject specific, IT IS NOT BAD TO BE CATEGORISED AS NONE! Don't include None in your analysis.",
            "calculations": [
                {
                    "label": "Frequency of Initiatives",
                    "logic": "(data) => data.reduce((acc, item) => { const key = item.other || 'None'; acc[key] = (acc[key] || 0) + 1; return acc; }, {})"
                },
                {
                    "label": "Top 3 Initiatives for In-Depth Analysis",
                    "logic": "(data) => { const initiatives = data.reduce((acc, item) => { const key = item.other || 'None'; acc[key] = (acc[key] || 0) + 1; return acc; }, {}); return Object.keys(initiatives).sort((a, b) => initiatives[b] - initiatives[a]).slice(0, 3); }"
                },
                {
                    "label": "Initiatives by Location (Top 3)",
                    "logic": "(data) => { const top3 = data.reduce((acc, item) => { const key = item.other || 'None'; acc[key] = (acc[key] || 0) + 1; return acc; }, {}); const top3Keys = Object.keys(top3).sort((a, b) => top3[b] - top3[a]).slice(0, 3); return data.filter(item => top3Keys.includes(item.other || 'None')).reduce((acc, item) => { acc[item.other] = acc[item.other] || { 'At School': 0, 'At Home': 0, 'Other': 0, 'At a Place of Worship': 0 }; const location = item.location || 'Unspecified'; if (location === 'at school') acc[item.other]['At School']++; if (location === 'at home') acc[item.other]['At Home']++; if (location === 'other') acc[item.other]['Other']++; if (location === 'at a place of worship') acc[item.other]['At a Place of Worship']++; return acc; }, {}); }"
                },
                {
                    "label": "Initiatives by Year Group (Top 3)",
                    "logic": "(data) => { const top3 = data.reduce((acc, item) => { const key = item.other || 'None'; acc[key] = (acc[key] || 0) + 1; return acc; }, {}); const top3Keys = Object.keys(top3).sort((a, b) => top3[b] - top3[a]).slice(0, 3); return data.filter(item => top3Keys.includes(item.other || 'None')).reduce((acc, item) => { acc[item.other] = acc[item.other] || {}; const year = `Year ${item.yearGroup}`; acc[item.other][year] = (acc[item.other][year] || 0) + 1; return acc; }, {}); }"
                },
                {
                    "label": "Strongest/Weakest Initiatives by Confidence (Top 3)",
                    "logic": "(data) => { const top3 = data.reduce((acc, item) => { const key = item.other || 'None'; acc[key] = (acc[key] || 0) + 1; return acc; }, {}); const top3Keys = Object.keys(top3).sort((a, b) => top3[b] - top3[a]).slice(0, 3); const confidenceData = data.filter(item => top3Keys.includes(item.other || 'None')).reduce((acc, item) => { acc[item.other] = acc[item.other] || { total: 0, count: 0 }; acc[item.other].total += (item.confidence || 0); acc[item.other].count++; return acc; }, {}); return Object.keys(confidenceData).reduce((result, key) => { const avg = confidenceData[key].total / confidenceData[key].count; result[key] = { 'Average Confidence': avg.toFixed(2),  'Entries': confidenceData[key].count }; return result; }, {}); }"
                },
                {
                    "label": "Strongest/Weakest Initiatives by Understanding (Top 3)",
                    "logic": "(data) => { const top3 = data.reduce((acc, item) => { const key = item.other || 'None'; acc[key] = (acc[key] || 0) + 1; return acc; }, {}); const top3Keys = Object.keys(top3).sort((a, b) => top3[b] - top3[a]).slice(0, 3); const understandingData = data.filter(item => top3Keys.includes(item.other || 'None')).reduce((acc, item) => { acc[item.other] = acc[item.other] || { total: 0, count: 0 }; acc[item.other].total += (item.understanding || 0); acc[item.other].count++; return acc; }, {}); return Object.keys(understandingData).reduce((result, key) => { const avg = understandingData[key].total / understandingData[key].count; result[key] = { 'Average Understanding': avg.toFixed(2), 'Entries': understandingData[key].count }; return result; }, {}); }"
                },
                {
                    "label": "Roles Taken by Initiative (Top 3)",
                    "logic": "(data) => { const top3 = data.reduce((acc, item) => { const key = item.other || 'None'; acc[key] = (acc[key] || 0) + 1; return acc; }, {}); const top3Keys = Object.keys(top3).sort((a, b) => top3[b] - top3[a]).slice(0, 3); return data.filter(item => top3Keys.includes(item.other || 'None')).reduce((acc, item) => { acc[item.other] = acc[item.other] || {}; acc[item.other][item.role] = (acc[item.other][item.role] || 0) + 1; return acc; }, {}); }"
                },
                {
                    "label": "Actions Benefiting Others by Initiative (Top 3)",
                    "logic": "(data) => { const top3 = data.reduce((acc, item) => { const key = item.other || 'None'; acc[key] = (acc[key] || 0) + 1; return acc; }, {}); const top3Keys = Object.keys(top3).sort((a, b) => top3[b] - top3[a]).slice(0, 3); return data.filter(item => top3Keys.includes(item.other || 'None')).reduce((acc, item) => { if (item.affects === 'others') acc[item.other] = (acc[item.other] || 0) + 1; return acc; }, {}); }"
                }
            ]
        },
        {
            "title": "Prestige Factor Analysis",
            "prompt": `Give an in depth analysis of what contexts students have an increased prestige factor, It's an AI assessed category which highlights achievements with a sense of prestige. lower prestige score is not always bad, it could be things like helping others in small ways that does not have the same prestige as winning a competition.
            
            You don't need to explain the prestige factor in depth in the introduction, but when posing questions include this understanding that areas could be showing greater/less chance for feelings of prestige and recognition`,
            "calculations": [
                {
                    "label": "Prestige Factor Average/Baseline",
                    "logic": "(data) => { const total = data.reduce((sum, item) => sum + (item.impressiveSorting || 0), 0); const count = data.filter(item => item.impressiveSorting != null).length; const average = (total / count).toFixed(2); return { average }; }"
                },
                {
                    "label": "Prestige Factor by Subject (Averages)",
                    "logic": "(data) => { const subjectData = data.reduce((acc, item) => { const subject = item.subject || 'None'; acc[subject] = acc[subject] || { total: 0, count: 0 }; acc[subject].total += (item.impressiveSorting || 0); acc[subject].count++; return acc; }, {}); return Object.keys(subjectData).reduce((result, subject) => { const avg = subjectData[subject].total / subjectData[subject].count; result[subject] = avg.toFixed(2); return result; }, {}); }"
                },
                {
                    "label": "Prestige Factor by Other Values (Averages)",
                    "logic": "(data) => { const otherData = data.reduce((acc, item) => { const other = item.other || 'None'; acc[other] = acc[other] || { total: 0, count: 0 }; acc[other].total += (item.impressiveSorting || 0); acc[other].count++; return acc; }, {}); return Object.keys(otherData).reduce((result, other) => { const avg = otherData[other].total / otherData[other].count; result[other] = avg.toFixed(2); return result; }, {}); }"
                },
                {
                    "label": "Prestige Factor by Location (Averages)",
                    "logic": "(data) => { const locationData = data.reduce((acc, item) => { const location = item.location || 'Unspecified'; acc[location] = acc[location] || { total: 0, count: 0 }; acc[location].total += (item.impressiveSorting || 0); acc[location].count++; return acc; }, {}); return Object.keys(locationData).reduce((result, location) => { const avg = locationData[location].total / locationData[location].count; result[location] = avg.toFixed(2); return result; }, {}); }"
                },
                {
                    "label": "Prestige Factor by Year Group (Averages)",
                    "logic": "(data) => { const yearData = data.reduce((acc, item) => { const year = `Year ${item.yearGroup}`; acc[year] = acc[year] || { total: 0, count: 0 }; acc[year].total += (item.impressiveSorting || 0); acc[year].count++; return acc; }, {}); return Object.keys(yearData).reduce((result, year) => { const avg = yearData[year].total / yearData[year].count; result[year] = avg.toFixed(2); return result; }, {}); }"
                },
                {
                    "label": "Prestige Factor by Role (Averages)",
                    "logic": "(data) => { const roleData = data.reduce((acc, item) => { const role = item.role || 'Unspecified'; acc[role] = acc[role] || { total: 0, count: 0 }; acc[role].total += (item.impressiveSorting || 0); acc[role].count++; return acc; }, {}); return Object.keys(roleData).reduce((result, role) => { const avg = roleData[role].total / roleData[role].count; result[role] = avg.toFixed(2); return result; }, {}); }"
                },
                {
                    "label": "Highest and Lowest Prestige Factor Entries",
                    "logic": "(data) => { const sorted = data.filter(item => item.impressiveSorting != null).sort((a, b) => b.impressiveSorting - a.impressiveSorting); const highest = sorted.slice(0, 5).map(entry => ({ subject: entry.subject || 'None', other: entry.other || 'None', location: entry.location || 'Unspecified', impressiveSorting: entry.impressiveSorting })); const lowest = sorted.slice(-5).map(entry => ({ subject: entry.subject || 'None', other: entry.other || 'None', location: entry.location || 'Unspecified', impressiveSorting: entry.impressiveSorting })); return { highest, lowest }; }"
                }
            ]
        }
        ,
        {
            "title": "Gender Analysis",
            "prompt": "Do a detailed assessment of gender differences in character development. Include at least one table.",
            "calculations": [
                {
                    "label": "Gender of Users",
                    "logic": "(data) => { const userGender = data.reduce((acc, item) => { if (item.uid && item.gender) { acc[item.uid] = item.gender; } return acc; }, {}); return Object.values(userGender).reduce((acc, gender) => { acc[gender] = (acc[gender] || 0) + 1; return acc; }, {}); }"
                },
                {
                    "label": "Gender of Entries",
                    "logic": "(data) => data.reduce((acc, item) => { const gender = item.gender || 'Unspecified'; acc[gender] = (acc[gender] || 0) + 1; return acc; }, {})"
                },
                {
                    "label": "Character Areas Developed by Gender",
                    "logic": "(data) => data.reduce((acc, item) => { const gender = item.gender || 'Unspecified'; acc[gender] = acc[gender] || {}; acc[gender][item.areaName] = (acc[gender][item.areaName] || 0) + 1; return acc; }, {})"
                },
                {
                    "label": "Gender by Location",
                    "logic": "(data) => data.reduce((acc, item) => { const gender = item.gender || 'Unspecified'; const location = item.location || 'Unspecified'; acc[gender] = acc[gender] || { 'At School': 0, 'At Home': 0, 'Other': 0, 'At a Place of Worship': 0 }; if (location === 'at school') acc[gender]['At School']++; if (location === 'at home') acc[gender]['At Home']++; if (location === 'other') acc[gender]['Other']++; if (location === 'at a place of worship') acc[gender]['At a Place of Worship']++; return acc; }, {})"
                },
                {
                    "label": "Gender by Year Group",
                    "logic": "(data) => data.reduce((acc, item) => { const gender = item.gender || 'Unspecified'; const year = `Year ${item.yearGroup}`; acc[gender] = acc[gender] || {}; acc[gender][year] = (acc[gender][year] || 0) + 1; return acc; }, {})"
                },
                {
                    "label": "Strongest/Weakest Confidence by Gender",
                    "logic": "(data) => { const confidenceData = data.reduce((acc, item) => { const gender = item.gender || 'Unspecified'; acc[gender] = acc[gender] || { total: 0, count: 0 }; acc[gender].total += (item.confidence || 0); acc[gender].count++; return acc; }, {}); return Object.keys(confidenceData).reduce((result, key) => { const avg = confidenceData[key].total / confidenceData[key].count; result[key] = { 'Average Confidence': avg.toFixed(2),  'Entries': confidenceData[key].count }; return result; }, {}); }"
                },
                {
                    "label": "Strongest/Weakest Understanding by Gender",
                    "logic": "(data) => { const understandingData = data.reduce((acc, item) => { const gender = item.gender || 'Unspecified'; acc[gender] = acc[gender] || { total: 0, count: 0 }; acc[gender].total += (item.understanding || 0); acc[gender].count++; return acc; }, {}); return Object.keys(understandingData).reduce((result, key) => { const avg = understandingData[key].total / understandingData[key].count; result[key] = { 'Average Understanding': avg.toFixed(2),  'Entries': understandingData[key].count }; return result; }, {}); }"
                },
                {
                    "label": "Roles by Gender",
                    "logic": "(data) => data.reduce((acc, item) => { const gender = item.gender || 'Unspecified'; acc[gender] = acc[gender] || {}; acc[gender][item.role] = (acc[gender][item.role] || 0) + 1; return acc; }, {})"
                },
                {
                    "label": "Actions Benefiting Others by Gender",
                    "logic": "(data) => data.reduce((acc, item) => { const gender = item.gender || 'Unspecified'; if (item.affects === 'others') acc[gender] = (acc[gender] || 0) + 1; return acc; }, {})"
                },
                {
                    "label": "Support Sources by Gender",
                    "logic": "(data) => data.reduce((acc, item) => { const gender = item.gender || 'Unspecified'; acc[gender] = acc[gender] || {}; acc[gender][item.supportedBy || 'Unspecified'] = (acc[gender][item.supportedBy || 'Unspecified'] || 0) + 1; return acc; }, {})"
                }
            ]
        },
        {
            "title": "Support, independence, and teamwork",
            "prompt": "Explain who is involved in supporting achievements, if they are independent, or if they were supported or worked with their peers. supported by self means they were independent. ",
            "calculations": [
                {
                    "label": "Overall Distribution",
                    "logic": "(data) => data.reduce((acc, item) => { const supportedBy = item.supportedBy || 'Unspecified'; acc[supportedBy] = (acc[supportedBy] || 0) + 1; return acc; }, {})"
                },
                {
                    "label": "Support/influence by Subject",
                    "logic": "(data) => data.reduce((acc, item) => { const subject = item.subject || 'None'; const supportedBy = item.supportedBy || 'Unspecified'; acc[subject] = acc[subject] || {}; acc[subject][supportedBy] = (acc[subject][supportedBy] || 0) + 1; return acc; }, {})"
                },
                {
                    "label": "Support/influence by Other Values",
                    "logic": "(data) => data.reduce((acc, item) => { const other = item.other || 'None'; const supportedBy = item.supportedBy || 'Unspecified'; acc[other] = acc[other] || {}; acc[other][supportedBy] = (acc[other][supportedBy] || 0) + 1; return acc; }, {})"
                },
                {
                    "label": "Support/influence by Location",
                    "logic": "(data) => data.reduce((acc, item) => { const location = item.location || 'Unspecified'; const supportedBy = item.supportedBy || 'Unspecified'; acc[location] = acc[location] || {}; acc[location][supportedBy] = (acc[location][supportedBy] || 0) + 1; return acc; }, {})"
                },
                {
                    "label": "Support/influence by Year Group",
                    "logic": "(data) => data.reduce((acc, item) => { const year = `Year ${item.yearGroup}`; const supportedBy = item.supportedBy || 'Unspecified'; acc[year] = acc[year] || {}; acc[year][supportedBy] = (acc[year][supportedBy] || 0) + 1; return acc; }, {})"
                },
                {
                    "label": "Support/influence by Role",
                    "logic": "(data) => data.reduce((acc, item) => { const role = item.role || 'Unspecified'; const supportedBy = item.supportedBy || 'Unspecified'; acc[role] = acc[role] || {}; acc[role][supportedBy] = (acc[role][supportedBy] || 0) + 1; return acc; }, {})"
                },
                {
                    "label": "Support/influence by Character Area",
                    "logic": "(data) => data.reduce((acc, item) => { const area = item.areaName || 'Unspecified'; const supportedBy = item.supportedBy || 'Unspecified'; acc[area] = acc[area] || {}; acc[area][supportedBy] = (acc[area][supportedBy] || 0) + 1; return acc; }, {})"
                },
                {
                    "label": "Most Frequent Support Sources",
                    "logic": "(data) => { const supportCounts = data.reduce((acc, item) => { const supportedBy = item.supportedBy || 'Unspecified'; acc[supportedBy] = (acc[supportedBy] || 0) + 1; return acc; }, {}); return Object.keys(supportCounts).sort((a, b) => supportCounts[b] - supportCounts[a]).slice(0, 5).map(source => ({ source, count: supportCounts[source] })); }"
                }
            ]
        }
        ,
        {
            "title": "Standout Days of Character Growth",
            "prompt": "try to see if there were any dates with specific initiatives or off timetable days. If there is a huge jump in usage where other or subject are extremely similar among entries it suggests an off timetable day. However it is also likely that there were not off timetable days in the data. If you can not be specific in the data on where off timetable days were do not go into detail. DO NOT LIST ALL DATES! If there are no off timetable days found keep the section very short and simpily say there was nothing in the data showing large groups of students doing the same activty on the same day. DONT USE TABLES OR LIST DATES",
            "calculations": [
                {
                    "label": "Top 10 Most Active Dates",
                    "logic": "(data) => { const dateCounts = data.reduce((acc, item) => { const date = item.date || 'Unspecified'; acc[date] = (acc[date] || 0) + 1; return acc; }, {}); return Object.keys(dateCounts).sort((a, b) => dateCounts[b] - dateCounts[a]).slice(0, 10); }"
                },
                {
                    "label": "Summary for Top 10 Most Active Dates",
                    "logic": "(data) => { const dateCounts = data.reduce((acc, item) => { const date = item.date || 'Unspecified'; acc[date] = (acc[date] || 0) + 1; return acc; }, {}); const top10Dates = Object.keys(dateCounts).sort((a, b) => dateCounts[b] - dateCounts[a]).slice(0, 10); return top10Dates.reduce((result, date) => { const filtered = data.filter(item => item.date === date); result[date] = { totalEntries: dateCounts[date], subjects: filtered.reduce((acc, entry) => { acc[entry.subject || 'None'] = (acc[entry.subject || 'None'] || 0) + 1; return acc; }, {}), other: filtered.reduce((acc, entry) => { acc[entry.other || 'None'] = (acc[entry.other || 'None'] || 0) + 1; return acc; }, {}), averageConfidence: (filtered.reduce((sum, entry) => sum + (entry.confidence || 0), 0) / filtered.length).toFixed(2), averageUnderstanding: (filtered.reduce((sum, entry) => sum + (entry.understanding || 0), 0) / filtered.length).toFixed(2) }; return result; }, {}); }"
                }
            ]
        },
        {
            "title": "Conclusion and recommendations",
            "prompt": "Conclude the report summarizing all key points of the previous sections. Read through the whole report (your responses only) and repeat questions you posed for improvement earlier in the report. Don't mention any numerical statistics here just point out what was identifed, previous questions you have posed in the report and maybe any further questions. Give the questions that the school should look into a line at a time in bold (<b>).",
            "calculations": []
        },
        // {
        //     "title": "Outstanding Achievements Appendix",
        //     "prompt": "This is an appendix, mentioning some outstanding achievements were found using AI and what kinds of things are mentioned. add a title for in and out of school, and within quote EXACTLY the characteristic and year group in bold with a description below. DON'T USE A TABLE HERE",
        //     "calculations": [
        //         {
        //             "label": "Top 3 Achievements Inside School",
        //             "logic": "(data) => { const filtered = data.filter(item => item.location === 'at school'); const sorted = filtered.sort((a, b) => ((b.understanding || 0) + (b.valueEstimate || 0) + (b.impressiveSorting || 0)) - ((a.understanding || 0) + (a.valueEstimate || 0) + (a.impressiveSorting || 0))); return sorted.slice(0, 3).map(item => ({ 'Year Group': `Year ${item.yearGroup}`, 'Characteristic': item.characteristic, 'Description': item.description })); }"
        //         },
        //         {
        //             "label": "Top 2 Achievements Outside School",
        //             "logic": "(data) => { const filtered = data.filter(item => item.location !== 'at school'); const sorted = filtered.sort((a, b) => ((b.understanding || 0) + (b.valueEstimate || 0) + (b.impressiveSorting || 0)) - ((a.understanding || 0) + (a.valueEstimate || 0) + (a.impressiveSorting || 0))); return sorted.slice(0, 2).map(item => ({ 'Year Group': `Year ${item.yearGroup}`, 'Characteristic': item.characteristic, 'Description': item.description })); }"
        //         }
        //     ]
        // },
    ]
};
