import React, { useState, useRef, useEffect } from "react";
import {
  getDatabase,
  ref as databaseRef,
  onValue,
  off,
} from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faMicrophoneSlash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import app from "../firebase"; // Assuming Firebase is initialized here
import ChatDisplay from "./charlieChatDisplay";

const customStyles = `
  @keyframes pulse-glow {
    0% { box-shadow: 0 0 10px rgba(0,123,255,0.3), 0 0 20px rgba(0,123,255,0.2); }
    50% { box-shadow: 0 0 20px rgba(0,123,255,0.5), 0 0 40px rgba(0,123,255,0.4); }
    100% { box-shadow: 0 0 10px rgba(0,123,255,0.3), 0 0 20px rgba(0,123,255,0.2); }
  }

  @keyframes gradient-shift {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }

  .interaction-button {
    transition: all 0.3s ease-in-out;
    background: linear-gradient(45deg, #007bff, #00c6ff);
    background-size: 200% 200%;
    animation: gradient-shift 5s ease infinite;
  }

  .interaction-button:hover {
    transform: scale(1.05);
  }

  .listening-active {
    animation: pulse-glow 2s infinite;
  }

  .bg-gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      circle at center, 
      rgba(0,123,255,0.2), 
      rgba(0,0,0,0) 70%
    );
    pointer-events: none;
  }

  .chat-panel {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 33vw;
    background: #f8f9fa;
    box-shadow: -2px 0 10px rgba(0,0,0,0.2);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .chat-panel.open {
    transform: translateX(0);
  }

  .main-content {
    transition: width 0.3s ease-in-out;
    width: 100%;
  }

  .main-content.shift-left {
    width: 67vw;
  }
`;

const AudioChat = ({ user, conversationIdProps, onClose  }) => {
  const [status, setStatus] = useState("idle");
  const [responseAudio, setResponseAudio] = useState(null);
  const [mediaBlobUrl, setMediaBlobUrl] = useState(null);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const mediaRecorderRef = useRef(null);

  useEffect(() => {


    if(conversationIdProps) {
      setConversationId(conversationIdProps)
    }
  if (!conversationId) return;

  setStatus("loading"); // Set status to loading initially

  const db = getDatabase(app);
  const conversationRef = databaseRef(db, `conversations/${user?.uid}/${conversationId}`);

  // Set up a listener for live updates
  const unsubscribe = onValue(
    conversationRef,
    (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setConversationHistory(data.conversationHistory || []);
        setStatus("idle"); // Set status back to idle once data is loaded
      } else {
        setConversationHistory([]);
        setStatus("idle"); // No data exists, but we're done loading
      }
    },
    (error) => {
      console.error("Error fetching conversation:", error);
      setConversationHistory([]);
      setStatus("error"); // Handle errors gracefully
    }
  );

  // Clean up the listener on component unmount or when conversationId changes
  return () => unsubscribe();
}, [conversationId, user?.uid]);


  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      setStatus("listening");

      const audioChunks = [];
      mediaRecorder.addEventListener("dataavailable", (event) => {
        audioChunks.push(event.data);
      });

      mediaRecorder.addEventListener("stop", () => {
        const audioBlob = new Blob(audioChunks);
        const blobUrl = URL.createObjectURL(audioBlob);
        setMediaBlobUrl(blobUrl);
        uploadAndProcessInput(audioBlob, "audio");
        setStatus("thinking");
      });

      mediaRecorder.start();
    });
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null;
    }
  };
  const uploadAndProcessInput = async (input, mode = "audio") => {
    try {
      const functions = getFunctions(app);
      let result;
  
      if (mode === "audio") {
        const storage = getStorage(app);
        const fileName = `audio/${user?.uid}/${Date.now()}.mp3`;
        const fileReference = storageRef(storage, fileName);
  
        // Upload the audio file to Firebase Storage
        await uploadBytes(fileReference, input);
        console.log("Audio uploaded successfully!");
  
        // Get the download URL of the uploaded file
        const downloadURL = await getDownloadURL(fileReference);
        console.log("Download URL obtained:", downloadURL);
  
        // Call Firebase Function to process the audio
        const processAudioUrl = httpsCallable(functions, "processAudioUrl");
        result = await processAudioUrl({
          audioUrl: downloadURL,
          conversationHistory,
          conversationId,
        });
      } else if (mode === "text") {
        // Call Firebase Function to process text input
        const textToAudioResponse = httpsCallable(functions, "textToAudioResponse");
        result = await textToAudioResponse({
          userText: input,
          conversationHistory,
          conversationId,
        });
      }
  
      console.log("Processing complete, result:", result);
  
      // Update conversationId if it's a new conversation
      if (!conversationId) {
        setConversationId(result.data.conversationId);
      }
  
      // Reference the response audio file
      const storage = getStorage(app);
      const responseStorageRef = storageRef(storage, result.data.storagePath);
  
      // Check for the existence of the processed audio file using exponential backoff
      const responseAudioUrl = await checkFileExists(responseStorageRef);
  
      console.log("Response audio file is ready:", responseAudioUrl);
  
      // Set the processed response audio file URL
      setResponseAudio(responseAudioUrl);
  
      // Update the status to indicate response is ready
      setStatus("response");
    } catch (error) {
      console.error("Error during upload or processing:", error);
      setStatus("idle"); // Reset to idle if an error occurs
    }
  };
  

  const checkFileExists = async (fileRef, maxRetries = 20) => {
    const baseDelay = 500; // Start with 500ms delay

    for (let attempt = 0; attempt < maxRetries; attempt++) {
      try {
        return await getDownloadURL(fileRef);
      } catch {
        if (attempt === maxRetries - 1) {
          throw new Error("File not found after retries");
        }
        const delay = baseDelay * 2 ** attempt; // Exponential backoff
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  };

  return (
    <>
      <style>{customStyles}</style>
      <div className={`min-vh-100 d-flex justify-content-center align-items-center bg-dark text-white position-relative overflow-hidden main-content ${isChatOpen ? "shift-left" : ""}`}>
        <div className="bg-gradient-overlay"></div>
        <div className="position-relative w-100 mw-600 text-center">
          {/* Back Button */}
          <button
            className="position-absolute top-0 start-0 m-3 btn btn-outline-light"
            onClick={onClose} 
          >
            Back
          </button>

          {/* Open Chat Button */}
          <button
            className="position-absolute top-0 end-0 m-3 btn btn-outline-primary"
            onClick={() => setIsChatOpen(!isChatOpen)}
          >
            {isChatOpen ? "Close Chat": "Open Chat"}
          </button>

          <div
            className={`mb-4 h4 text-center transition-all`}
          >

<div className="d-flex justify-content-center my-3">
            <div
            style={{width:"50%"}}
    className={`alert text-center py-2 px-4 rounded-pill alert-primary`}
  >
    {status === "idle" && "Click below to record your voice"}
    {status === "listening" && "Listening... (click below to stop)"}
    {status === "thinking" && "Processing..."}
    {status === "response" && "Click below to keep talking"}
  </div>
  </div>
          </div>
          <div
            onClick={status === "listening" ? stopRecording : startRecording}
            className={`interaction-button rounded-circle d-flex align-items-center justify-content-center mx-auto text-white border-0 ${status === "listening" ? "listening-active" : ""
              }`}
              style={{
                width: "50vh",
                height: "50vh",
                maxWidth: "90vw",
                maxHeight: "90vw",
                cursor: "pointer",
                boxShadow: "0 10px 30px rgba(0,0,0,0.2)",
                transform: status !== "idle" ? "scale(1)" : "scale(0.9)",
                backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/beworldclass-production.appspot.com/o/CharlieBotStatic%2Fmainbot.png?alt=media&token=abd7d03b-712a-4e7a-b535-b4d9244cd002')", // Add the URL here
                backgroundSize: "cover", // Ensures the image covers the button
                backgroundPosition: "center", // Centers the image
                backgroundRepeat: "no-repeat", // Prevents repeating
              }}
          >
           
           
          </div>

          {status === "response" && (
            <p
              className="text-center py-3 px-4 mx-auto my-3 rounded shadow-sm text-light animate__animated"
              style={{
                maxWidth: "80%",
                fontWeight: "bold",
                fontSize: "1.2rem",
                opacity: 1,
                maxHeight: "200px", // Set a max height large enough for your content
                overflow: "hidden", // Hide anything outside the max height
                transition: "max-height 4s ease, opacity 4s ease", // Animate both height and opacity
              }}
            >
              {
                conversationHistory
                  .filter(item => item.role === "assistant")
                  .slice(-1)
                  .map(item => item.content)
                  .join("") || "No assistant response found"
              }
            </p>
          )}

          {mediaBlobUrl && (
            <div className="mt-4 hidden">
              <p>Original Recording:</p>
              <audio src={mediaBlobUrl} controls />
            </div>
          )}
          {responseAudio && (
            <div className="mt-4 hidden">
              <p>GPT Response:</p>
              <audio src={responseAudio} controls autoPlay />
            </div>
          )}
        </div>
      </div>

      {/* Chat Panel */}
      <div className={`chat-panel bg-dark ${isChatOpen ? "open" : ""}`}>
        <div className="p-4">
          <h4>Chat Panel</h4>
          <ChatDisplay
  conversationHistory={conversationHistory}
  onSendMessage={(userText) => uploadAndProcessInput(userText, "text")}
/>
        </div>
      </div>
    </>
  );
};

export default AudioChat;
