import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getDatabase, ref as databaseRef, onValue } from "firebase/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMessage,
  faBookOpen,
  faCirclePlay,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import AudioChat from "./audioChat.js";

const CharlieMainMenu = ({ user }) => {
  const [conversations, setConversations] = useState([]);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [activeConversationId, setActiveConversationId] = useState(null);

  useEffect(() => {
    if (!user?.uid) return;

    const db = getDatabase();
    const userConversationsRef = databaseRef(db, `conversations/${user.uid}`);

    // Fetch conversations from Firebase
    const unsubscribe = onValue(userConversationsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Transform data into an array format
        const conversationsList = Object.keys(data).map((id) => ({
          id,
          title: data[id].conversationHistory[0]?.content || "Untitled",
        }));
        setConversations(conversationsList);
      } else {
        setConversations([]);
      }
    });

    // Cleanup listener on unmount
    return () => unsubscribe();
  }, [user?.uid]);

  const handleNewConversation = () => {
    setActiveConversationId(null); // New conversation has no ID
    setIsChatVisible(true); // Show AudioChat
  };

  const handleConversationClick = (conversationId) => {
    setActiveConversationId(conversationId); // Set ID of selected conversation
    setIsChatVisible(true); // Show AudioChat
  };

  const handleCloseChat = () => {
    setIsChatVisible(false); // Hide AudioChat
    setActiveConversationId(null); // Reset conversation ID
  };

  if (isChatVisible) {
    return (
      <AudioChat
        user={user}
        conversationIdProps={activeConversationId}
        onClose={handleCloseChat}
      />
    );
  }

  return (
    <div style={{ height: "100vh" }} className="bg-dark">
      <div className="container py-4">
        {/* Header */}
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h1 className="fs-3 fw-bold text-light">Mr Charlie Bot</h1>
          <button
            className="btn btn-primary d-flex align-items-center gap-2"
            onClick={handleNewConversation}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span>New Chat</span>
          </button>
        </div>

        {/* Main Content */}
        <div className="row g-4">
          {/* Conversations Section */}
          <div className="col-md-6">
            <div className="bg-white rounded-3 shadow p-4">
              <h2 className="fs-5 fw-bold text-dark d-flex align-items-center gap-2">
                <FontAwesomeIcon icon={faMessage} className="text-primary" />
                My Conversations
              </h2>
              <div className="mt-3">
                {conversations.length > 0 ? (
                  conversations.map((conv) => (
                    <div
                      key={conv.id}
                      className="d-flex align-items-center p-3 mb-3 bg-light rounded shadow-sm position-relative"
                      onClick={() => handleConversationClick(conv.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="flex-grow-1">
                        <h3 className="fs-6 fw-semibold text-dark">{conv.title}</h3>
                      </div>
                      <FontAwesomeIcon
                        icon={faCirclePlay}
                        className="text-primary fs-5"
                      />
                    </div>
                  ))
                ) : (
                  <p className="text-muted">No conversations available</p>
                )}
              </div>
            </div>
          </div>

          {/* Resources Section */}
          <div className="col-md-6">
            <div className="bg-white rounded-3 shadow p-4">
              <h2 className="fs-5 fw-bold text-dark d-flex align-items-center gap-2">
                <FontAwesomeIcon icon={faBookOpen} className="text-primary" />
                Learning Resources
              </h2>
              <div className="mt-3">
                {/* Add your resource listing here */}
                <p className="text-muted">Resources coming soon...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CharlieMainMenu.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string.isRequired,
  }).isRequired,
};

export default CharlieMainMenu;
