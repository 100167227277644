import React from "react";
import ImprovedGrid from "./grid/improvedGrid";
import StudentDetails from "./studentDetails";
import { CSVLink } from 'react-csv';

class AllStudents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userSubmissions: [],
            selectedStudent: null
        };
    }

    componentDidMount() {
        this.modifySubmissions();
        console.log("users in all students", this.props.users)
    }

    componentDidUpdate(prevProps) {
        // Check if extendedSubmissions or users have changed
        if (prevProps.extendedSubmissions !== this.props.extendedSubmissions || 
            prevProps.users !== this.props.users) {
            this.modifySubmissions();
        }
    }
    

    modifySubmissions = () => {
        const { extendedSubmissions, users } = this.props; // Incoming data
        const { userSubmissions } = this.state; // Current state data
    
        // If neither `extendedSubmissions` nor `users` is provided, do nothing
        if ((!extendedSubmissions || extendedSubmissions.length === 0) &&
            (!users || users.length === 0)) {
            return;
        }
    
        // Build a map from the existing state for efficient merging
        const existingMap = userSubmissions.reduce((map, user) => {
            map[user.uid] = { ...user };
            return map;
        }, {});
    
        // Update with `extendedSubmissions` data
        if (extendedSubmissions && extendedSubmissions.length > 0) {
            extendedSubmissions.forEach((submission) => {
                const { uid, characteristic, pledge, description, badge } = submission;
    
                // Merge new or updated data into the existing map
                if (!existingMap[uid]) {
                    existingMap[uid] = { ...submission, badge: 0, characteristicsCompleted: {} };
                }
    
                const user = existingMap[uid];
    
                // Merge all fields from the submission
                Object.assign(user, submission);
    
                // Update characteristics and badge count if a characteristic is provided
                if (characteristic) {
                    user.characteristicsCompleted = {
                        ...user.characteristicsCompleted,
                        [characteristic]: {
                            completed: true,
                            pledge: pledge || '',
                            description: description || '',
                        },
                    };
                    user.badge = Object.keys(user.characteristicsCompleted).length; // Recalculate badge count
                }
            });
        }
    
        // Update with `users` data
        if (users && users.length > 0) {
            users.forEach((updatedUser) => {
                const existingUser = existingMap[updatedUser.uid];
    
                if (existingUser) {
                    // Merge updated fields into the existing user data
                    existingMap[updatedUser.uid] = {
                        ...existingUser,
                        ...updatedUser,
                    };
                } else {
                    // Add new user if not already present
                    existingMap[updatedUser.uid] = { ...updatedUser, badge: 0, characteristicsCompleted: {} };
                }
            });
        }
    
        // Remove users no longer present in the `users` prop
        const filteredSubmissions = Object.values(existingMap).filter(user =>
            users.some(existingUser => existingUser.uid === user.uid)
        );
    
        // Update state with the filtered data
        this.setState({ userSubmissions: filteredSubmissions });
    };
    
    
    
    

    handleStudentSelect = (student) => {
        const selectedStudent = this.props.users.find(user => user.uid === student.uid);
        this.setState({ selectedStudent });
    };

    handleBackToList = () => {
        this.setState({ selectedStudent: null });
    }

    render() {
        const { userSubmissions, selectedStudent } = this.state;

        if (selectedStudent) {
            return (
                <StudentDetails
                student={selectedStudent}
                extendedSubmissions={this.props.extendedSubmissions}
                onBack={this.handleBackToList}
                areasData={this.props.areasData}  
                schoolData={this.props.schoolData}
            />
            );
        }

        const columns = [
            { key: 'firstName', label: 'First Name' },
            { key: 'lastName', label: 'Last Name' },
            { key: 'email', label: 'Email' },
            { key: 'yearGroup', label: 'Year' },
            { key: 'class', label: 'Class' },
            { key: 'badge', label: 'Badge Count' }
        ];

        return (
            <div className="container">
                <h1 className="my-3">All Students</h1>
           

                <div className="d-flex justify-content-end mb-3">
                    <CSVLink
                        data={userSubmissions}
                        headers={columns.map(col => ({ label: col.label, key: col.key }))}
                        filename={"BeWorldClass_AllStudents.csv"}
                        className="btn btn-outline-primary"
                    >
                        Download CSV
                    </CSVLink>
                </div>

                <ImprovedGrid
                    extendedSubmissions={userSubmissions}
                    columns={columns}
                    onRowClick={this.handleStudentSelect}
                />
            </div>
        );
    }
}

export default AllStudents;
