import React from "react";

import { Formik, Field, Form, ErrorMessage } from "formik";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { getDatabase, ref, set, onValue } from "firebase/database";
import app from '../firebase.js'
//import { firebase_app } from 'firebase';

import firebaseConfig from "../firebaseConfig.js"


class SignUpStudent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            submitAttempt: false,
            values: {}

        }
    }

    componentDidMount() {



    }

    signUp = () => {



        const email = this.props.schoolData.stage === "primary" ? `b3w0r1d+${this.state.values.email}@beworldclass.org` : this.state.values.email

        createUserWithEmailAndPassword(getAuth(app), email, this.state.values.password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;

                this.setState({ user: user })
                this.createUserInDatabase(user)

                console.log(this.state.user)
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                console.log(errorMessage)
                // ..
            });
    }

    createUserInDatabase = (user) => {
        const database = getDatabase(app)
        const inSchoolPath = ref(database, 'schools/' + this.props.schoolData.path + '/students/' + user.uid);
        const userPath = ref(database, 'users/' + user.uid);

        set(inSchoolPath, {
            details: {
                firstName: this.state.values.firstName,
                lastName: this.state.values.lastName,
                yearGroup: this.state.values.yearGroup,
                class: this.state.values.class,
                gender: this.state.values.gender
            },
        });

        set(userPath, {
            role: "student",
            schools: {
                [this.props.schoolData.path]: true,
            }
        });




    }

    clearClipboard = () => {
        // Clear clipboard by copying a space
        console.log("clearClipboard")
        navigator.clipboard.writeText(' ');
    };

    render() {

        return (
            <div style={{ textAlign: "center", padding: "2rem" }}>
                <div className="card" style={{ textAlign: "left", padding: "2rem" }}>

                    <Formik
                        initialValues={{}}
                        onSubmit={async values => {
                            await new Promise(resolve => setTimeout(resolve, 500));
                            if (Object.keys(this.state.errors).length === 0) {

                                console.log("submit")
                                this.setState({ submitAttempt: true, values: values }, () => {
                                    this.signUp();
                                });

                            }
                            else {
                                console.log("invalid fields, this is my state", this.state.errors)
                                this.setState({ submitAttempt: true })
                            }

                        }}
                        validate={values => {
                            const errors = checkForInvalidFields(values, this.props.schoolData.stage);
                            this.setState({ errors: errors })

                        }
                        }
                    >

                        <Form >

                            <h4 className="form-heading">Sign up</h4>

                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <label >First name</label>
                                    <Field name="firstName" type="text" className={`${this.state.errors.firstName && this.state.submitAttempt ? "is-invalid" : ""} form-control`} />
                                    <div class="invalid-feedback">{this.state.errors.firstName}</div>
                                </div>

                                <div className="col-lg-6 col-12">

                                    <label >Last Name</label>
                                    <Field name="lastName" type="text" className={`${this.state.errors.lastName && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />
                                    <div class="invalid-feedback">{this.state.errors.lastName}</div>
                                </div>
                            </div>
                            <div className="row">

                                {this.props.schoolData.stage === "primary" ? <>
                                    <div className="col-lg-6 col-12">
                                        <label >Create username</label>
                                        <Field name="email" type="text" className={`${this.state.errors.email && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />
                                        <div class="invalid-feedback">{this.state.errors.email}</div>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <label >Confirm username</label>
                                        <Field name="confirmEmail" type="text" className={`${this.state.errors.confirmEmail && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />
                                        <div class="invalid-feedback">{this.state.errors.confirmEmail}</div>
                                    </div>

                                </> :
                                    <>
                                        <div className="col-lg-6 col-12">
                                            <label >Your School Email Address</label>
                                            <Field name="email" type="email" className={`${this.state.errors.email && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />

                                            <div class="invalid-feedback">{this.state.errors.email}</div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label >Confirm Email Address</label>
                                            <Field name="confirmEmail"
                                                onPaste={(e) => e.preventDefault()}
                                                type="email" className={`${this.state.errors.confirmEmail && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />
                                            <div class="invalid-feedback">{this.state.errors.confirmEmail}</div>
                                        </div>
                                        <h5 className="col-12" >
                                            <span className="badge badge-pill badge-primary">Important!</span> Make extra sure your email address is correct!
                                        </h5>
                                    </>
                                }


                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <label >Create Password</label>
                                    <Field name="password" type="password" className={`${this.state.errors.password && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />
                                    <div class="invalid-feedback">{this.state.errors.password}</div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <label >Confirm Password</label>
                                    <Field name="confirmPassword" type="password" className={`${this.state.errors.confirmPassword && this.state.submitAttempt ? "is-invalid" : ""} form-control`}
                                        onPaste={(e) => e.preventDefault()}
                                        placeholder=" " />
                                    <div class="invalid-feedback">{this.state.errors.confirmPassword}</div>
                                </div>
                            </div>
                            {/* Year Group and Class/Tutor Group */}
                            <div className="row">
                                <div className="col-12">
                                    <label>Year Group</label>
                                    <Field name="yearGroup" type="number" className={`${this.state.errors.yearGroup && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />
                                    <div className="invalid-feedback">{this.state.errors.yearGroup}</div>
                                </div>
                                <div className="col-12">
                                    <label>Class/Tutor Group</label>
                                    {this.props.schoolData.groups ? (
                                        <Field as="select" name="class" className={`${this.state.errors.class && this.state.submitAttempt ? "is-invalid" : ""} form-control`}>
                                            <option value="" disabled>Select a group</option>
                                            {this.props.schoolData.groups.map((group, index) => (
                                                <option key={index} value={group}>{group}</option>
                                            ))}
                                        </Field>
                                    ) : (
                                        <Field name="class" type="text" className={`${this.state.errors.class && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />
                                    )}
                                    <div className="invalid-feedback">{this.state.errors.class}</div>
                                </div>

                            </div>

                            {/* Gender */}
                            <div className="row">
                                <div className="col-12">
                                    <label>Gender</label>
                                    <Field as="select" name="gender" className={`${this.state.errors.gender && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" ">
                                        <option></option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                    </Field>
                                    <div className="invalid-feedback">{this.state.errors.gender}</div>
                                </div>
                            </div>


                            <button className="btn btn-primary" type="submit">Submit</button>

                        </Form >
                    </Formik >
                </div >
            </div >
        );
    }
}

export default SignUpStudent;



function checkForInvalidFields(values, stage) {
    const errors = {};

    if (!values.firstName) {
        errors.firstName = 'Required';
    }
    if (!values.lastName) {
        errors.lastName = 'Required';
    }

    if (stage === "primary") {
        if (!values.email) {
            errors.email = 'Required';
        } else if (!/^[a-zA-Z0-9_]*$/.test(
            values.email
        )) {
            errors.email = 'Invalid username. Only use letters and numbers. No spaces';
        }

    } else {

        if (!values.email) {
            errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
            values.email
        )) {
            errors.email = 'Invalid email address';
        }

    }
    if (values.email != values.confirmEmail) {
        errors.confirmEmail = 'Does not match';
    }

    if (!values.password) {
        errors.password = 'Required';
    } else if (values.password.length < 6) {

        errors.password = 'Password must be at least 6 characters in length';

    }


    if (values.password != values.confirmPassword) {
        errors.confirmPassword = 'Password does not match';
    }
    if (!values.yearGroup) {
        errors.yearGroup = 'Required';
    }

    if (values.yearGroup < 0) {
        errors.yearGroup = 'Your year group is probably not a negative number';
    }

    if (!values.class) {
        errors.class = 'Required';
    }
    if (!values.gender) {
        errors.gender = 'Required';
    }
    return errors;
}
