export function convertTableToBarChart(htmlString) {
    try {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");
        const tables = doc.querySelectorAll("table");

        tables.forEach((table) => {
            // Check for valid structure
            const headers = Array.from(table.querySelectorAll("th"))
                .map((th) => th?.textContent?.trim())
                .filter(Boolean);

            const rows = Array.from(table.querySelectorAll("tbody tr"))
                .map((tr) =>
                    Array.from(tr.querySelectorAll("td"))
                        .map((td) => td?.textContent?.trim())
                        .filter(Boolean)
                );

            // Skip tables that do not have exactly 2 columns
            if (headers.length !== 2) {
                console.warn("Skipping table: Table does not have exactly 2 columns in the header.");
                return;
            }

         

            // Skip tables with more than 10 rows
            if (rows.length > 10) {
                console.warn("Skipping table: Too many rows (more than 10).");
                return;
            }

            // Skip tables with labels in the first column exceeding 50 characters
            if (rows.some((row) => row[0]?.length > 50)) {
                console.warn("Skipping table: One or more labels in the first column exceed 50 characters.");
                return;
            }

            const numericValues = [];
            const validRows = [];
            rows.forEach((row) => {
                if (row.length === 2) {
                    const numericValue = parseFloat(row[1]);
                    if (!isNaN(numericValue)) {
                        numericValues.push(numericValue);
                        validRows.push(row);
                    }
                }
            });

            if (numericValues.length === 0) return;

            // Create the chart container
            const chartContainer = document.createElement("div");
            chartContainer.classList.add("converted-chart-container");

            const maxValue = Math.max(...numericValues);

            // Generate the bar groups
            validRows.forEach(([label, value]) => {
                const numericValue = parseFloat(value);

                const barGroup = document.createElement("div");
                barGroup.classList.add("converted-bar-group");

                // Value above the bar
                const barValue = document.createElement("div");
                barValue.classList.add("converted-bar-value");
                barValue.textContent = numericValue.toPrecision(3);

                // Bar section
                const barSection = document.createElement("div");
                barSection.classList.add("converted-bar-section");

                const bar = document.createElement("div");
                bar.classList.add("converted-bar");
                bar.style.height = `${(numericValue / maxValue) * 100}%`;

                barSection.appendChild(bar);

                // Label section
                const labelSection = document.createElement("div");
                labelSection.classList.add("converted-label-section");
                labelSection.textContent = label;

                barGroup.appendChild(barValue);
                barGroup.appendChild(barSection);
                barGroup.appendChild(labelSection);

                chartContainer.appendChild(barGroup);
            });

            // Replace the table with the chart container
            table.replaceWith(chartContainer);
        });

        return doc.body.innerHTML;
    } catch (error) {
        console.error("Error converting tables to bar charts:", error.message);
        return htmlString;
    }
}
