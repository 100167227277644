import React from "react";
import { getDatabase, ref, update } from "firebase/database";
import app from "../firebase";

class DeleteStudentAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
        };
    }

    deleteAccount = async () => {
        const database = getDatabase(app);
        const studentRef = ref(database, `schools/${this.props.schoolData.path}/students/${this.props.student.uid}/details`);

        try {
            await update(studentRef, { hidden: true });
            this.setState({ message: "Account successfully deleted." });

            // Close the modal after 2 seconds
            setTimeout(() => {
                this.props.closeAction();
            }, 2000);
        } catch (error) {
            console.error("Error deleting account:", error);
            this.setState({ message: "Failed to delete the account." });
        }
    };

    render() {
        return (
           
                    <div className="">
                        <h2>Delete Account</h2>
                        
                        <br/>
                        <h4>
                            Student: <strong>{this.props.student.firstName} {this.props.student.lastName}</strong>
                        </h4>

                        <h4>
                            Email: <strong>{this.props.student.email}</strong>
                        </h4>

                        <h4 style={{marginTop:"3rem", textAlign:"right"}} className="text-danger">
                            Are you sure you want to delete this account? This action cannot be undone.
                        </h4>
                        <div className="text-end">
                            <button
                                className="btn btn-secondary me-2"
                                onClick={this.props.closeAction}
                            >
                                Go Back
                            </button>
                            <button
                                className="btn btn-danger"
                                onClick={this.deleteAccount}
                            >
                                Confirm Delete
                            </button>
                        </div>
                        {this.state.message && (
                            <div className="mt-3 alert alert-info">{this.state.message}</div>
                        )}
                    </div>
              
        );
    }
}

export default DeleteStudentAccount;
